/** Adding Fonts starts **/

@font-face {
  font-family: "varela_reg";
  src: url(./assets/fonts/Varela-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "karla_reg";
  src: url(./assets/fonts/Karla-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "karla_bold";
  src: url(./assets/fonts/Karla-Bold.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "barlow_med";
  src: url(./assets/fonts/Barlow-Medium.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "barlow_reg";
  src: url(./assets/fonts/Barlow-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "barlow_bold";
  src: url(./assets/fonts/Barlow-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: "quicksand_med";
  src: url(./assets/fonts/Quicksand-Medium.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "quicksand_reg";
  src: url(./assets/fonts/Quicksand-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "quicksand_bold";
  src: url(./assets/fonts/Quicksand-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: "arvo_bold";
  src: url(./assets/fonts/Arvo-Bold.ttf);
}
@font-face {
  font-family: "arvo_reg";
  src: url(./assets/fonts/Arvo-Regular.ttf);
}
@font-face {
  font-family: "cabin_bold";
  src: url(./assets/fonts/Cabin-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: "cabin_reg";
  src: url(./assets/fonts/Cabin-Regular.ttf);
}
@font-face {
  font-family: "segoe_bold";
  src: url("./assets/fonts/Segoe UI Bold.woff");
  font-weight: normal;
}

/** Adding Fonts ends **/

html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (min-width: 360px) {
  html {
    overflow-y: scroll;
  }
}
@media (min-width: 576px) {
  html {
    overflow-y: scroll;
  }
}
@media (min-width: 768px) {
  html {
    overflow-y: scroll;
  }
} /* 
@media (min-width: 992px) {
  html {
    overflow-y: hidden;
  }
}
@media (min-width: 1200px) {
  html {
    overflow-y: hidden;
  }
} */
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "quicksand_reg";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "barlow_bold";
  font-weight: normal;
}

h6.HeaderListSubText {
  font-family: "barlow_reg" !important;
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/** Common classes **/

.wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.dvaraBrownBG {
  background-color: #72310c;
}
.dvaraGreenBG {
  background-color: #a3c614;
}

.testBord {
  border: solid 1px #ff0000;
}
.dvaraBrownText {
  color: rgba(114, 49, 12, 1);
}
.dvaraGreenText {
  color: rgb(172, 192, 93);
}
.darkGreenText {
  color: #436607;
}
.vSeperatorGreen {
  display: block;
  width: 1px;
  border: solid 1px rgba(163, 198, 20, 1);
}
.vSeperatorBrown {
  display: block;
  width: 1px;
  border: solid 1px rgba(114, 49, 12, 1);
}
.vSpaceElem {
  display: block;
  height: 5px;
}
.pad10 {
  padding: 10px;
}
.pad20 {
  padding: 20px;
}
.pad5 {
  padding: 5px;
}
.padTopLeft10 {
  padding: 10px 0px 0px 10px;
}
.padTopLeft5 {
  padding: 5px 0px 0px 5px;
}
.noPadding {
  padding: 0px 0px !important;
  margin: 0 !important;
}
.padTop10 {
  padding: 10px 0px 0px 0px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.margin-auto {
  margin: 0 auto;
}
.margin-top15 {
  margin-top: 15px !important;
}
.margin-top7 {
  margin-top: 7px !important;
}
.margin-left35 {
  margin-left: 35px !important;
}
.margin-right35 {
  margin-right: 35px !important;
}
.padding15 {
  padding: 15px;
}
.width-80 {
  width: 80% !important;
  margin: 0px auto;
}
.width-90 {
  width: 90% !important;
  margin: 0px auto;
}

.spacerSpan {
  display: inline-block;
  width: 2px;
}
.verticalSpacer50 {
  display: block;
  width: 100%;
  position: relative;
  height: 50px;
}
.verticalSpacer10 {
  display: block;
  width: 100%;
  position: relative;
  height: 10px;
}
.verticalSpacer20 {
  display: block;
  width: 100%;
  position: relative;
  height: 10px;
}

.testBord {
  border: solid 1px #ff0000;
}
.clearFixing {
  clear: both;
  display: block;
  width: 1px;
}
.border-bottom-greyDark {
  border-bottom: 1px solid #bcbcbc;
}

.showElement {
  display: inline-block;
}
.hideElement {
  display: none;
}
.errorText {
  font-weight: bold;
  color: #cf3131;
}
.warningText {
  font-weight: bold;
  color: #dd7530;
}
/** Main Styles start **/

.PageHeading {
  position: absolute;
  z-index: 10;
}

ol {
  list-style: none;
  display: flex;
  align-items: center;
}

.breadcrumb-separator {
  color: #333;
  margin: auto 6px;
  user-select: none;
}

.breadcrumb-collapser {
  display: flex;
  align-items: center;
}

.breadcrumb-collapser svg {
  cursor: pointer;
  transition: all 0.2s ease-out;
  color: #000;
  width: 25px;
  height: 25px;
}
.breadcrumb-collapser svg:hover,
.breadcrumb-collapser svg:focus {
  color: #999;
  transform: scale(1.15);
}

.breadcrumb-collapser svg:active {
  color: #333;
}

.defaultButtonElem {
  background-color: #dfdfdf !important;
  color: #333 !important;
  font-size: 0.8rem !important;
}
.defaultButtonElem:hover {
  background-color: #a3c614 !important;
  color: #fff !important;
  text-shadow: 2px 2px 2px 8px #555;
}
.defaultDisableButton {
  background-color: #dfdfdf !important;
  color: #333 !important;
  font-size: 0.8rem !important;
}
.defaultDisableButton:hover {
  text-shadow: 2px 2px 2px 8px #555;
}

.formMessage {
  font-size: 1.1rem;
  font-style: italic;
}
.errorMessage {
  color: #dd3838;
}
.successMessage {
  color: #a3c614;
}
.normalText {
  color: #555;
}
.midText{
  font-size: 0.8rem;
}
.errorBG{
  background-color:rgba(114, 49, 12, 0.3);
}
.successBG{
  background-color:rgb(163, 198, 20, 1);
}
.greenMessage{
  color: #5f740c;
}
.toastSuccessMessage{
  color: rgba(114, 49, 12, 1);
}
.boldHeaderText{
  font-size: 1.3rem;
  font-weight: bold;
}
.FourEmText{
  font-size: 4em;
}
.whiteText{
  color: #cdcdcd;
}

/** Toggle element styles starts **/
.toggleButtonElem {
  background-color: #dfdfdf;
  color: #333;
  font-size: 0.8rem;
}
.toggleButtonElem:hover {
  background-color: #a3c614 !important;
  color: #72310c !important;
  text-shadow: 2px 2px 2px 8px #555;
}
.toggleButtonActive {
  background-color: #a3c614 !important;
  color: #72310c !important;
  text-shadow: 2px 2px 2px 8px #555;
}
/** Toggle element styles ends **/

.no_data {
  text-align: center;
  position: absolute;
  margin-top: 20% !important;
}
.iFrame-Accounting{
    width:95% ;
   margin-left: 46px;
   height:700px;

          
}
.pageBreadCrumbHolder{
  padding: 0 !important;
}
.pageBreadCrumbItem{
  font-size: 0.7rem;
}
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 0.5rem;
    list-style: none;
    margin-left: 50px;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}