.width-90 {
  width: 90% !important;
  margin: 0px auto;
}

.whole-WrapData {
 
  font-size: 22px;
}

.card-outer {
  border: none;
  margin-bottom: 19px;
}
.card-Background {
  box-shadow: inset 10px 10px 50px #a3c614;
  font-size: 15px;
  /* cursor: pointer; */
  margin-top: 12px;
  line-height:1.9rem;
}
.adding-Icon {
  background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png)
    no-repeat calc(100% - 10px) center;
  background-size: 20px;
  cursor: pointer;
  font-size: 18px;
}
/* .mine {
  background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png)
    no-repeat calc(100% - 10px) center;
  background-size: 20px;
  cursor: pointer;
  font-size: 18px;
  background-color: rgba(163, 198, 20, 1);
  padding: 10px;
  color: rgba(114, 49, 12, 1);
} */
/* .no-inset {
  margin: 0px;
  border-radius: 20px;
  background-color: transparent;
}
.no-inset2 {
  background-color: transparent;
} */
.collapsed {
  color: rgba(114, 49, 12, 1);
  padding: 10px;
  background-color: rgba(163, 198, 20, 1);
  background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png);
}
.all-headings {
  background-color: rgba(163, 198, 20, 1);

  color: rgba(114, 49, 12, 1);
  /* padding: 10px; */
  /* font-size:23px; */
}
.heading-color {
  color: rgba(114, 49, 12, 1);
  margin-bottom:10px;
  font-size:22px;
}
.organization-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* .organization-box div {
  padding: 20px;
  margin-top: 10px;
  font-size: 18px;
  box-shadow: inset 10px 10px 50px #a3c614;
} */
.organization-box-details {
  padding: 25px;
  border-radius:20px;
  /* line-height:2rem; */
  line-height:2.3rem;

  margin-top: 10px;
  font-size: 19px;
  box-shadow: inset 10px 10px 50px #a3c614;
}

/* tbody {
  border: transparent;
} */
.business-activity {
  padding: 20px;
  margin-top: 10px;
  font-size: 18px;
  box-shadow: inset 10px 10px 50px #a3c614;
}
.footer2 {
  background-color: rgba(114, 49, 12, 1);
  /* padding-bottom: 70px; */
  /* padding-top: 70px; */
  box-shadow: 3px 3px 8px #777;
  /* position: static;
  bottom: 0; */
  color:white;
  font-size:15px;
  /* padding: 0.5rem 1rem; */
  /* height: 150px; */
  padding-top:10px;
  /* padding-bottom: 10px; activate it once u get details */
  /* delete it after data */
  height:80px;
}
.remove-backgroundTable tr {
  border-bottom-style: hidden !important;
  border-left-style: hidden !important;
  border-right-style: hidden !important;
  background-color: transparent !important;
}
.top-table-heading {
  color: rgba(114, 49, 12, 1);
  background-color: rgba(163, 198, 20, 1);
}
.lisence-table-alingment tr td{
  text-align: left !important;
}
.Scroll-Card-Data{
  /* height:150px; */
   height:250px;

  overflow-y: scroll;
}
.organization-box-details p {
  font-weight:600;
}
.Lisense-Name{
  font-weight:600;

}
/* .lisence-table-alingment tr{
   line-height:2rem;
} */
.Dvara-Contact-details{
  float:right;
}
.compliance-box{
  background-color:rgba(163,198,20,0.9);
  border:1px solid white;
  margin:10px;
  border-radius:10px;
  position: relative;
  padding:8px;
  font-size:16px;
  height:180px;
}
.compliance-box h6{
  text-align: center;
}
.compliance-images img{
  border-radius: 50%;
  height:60px;
  text-align: center;
  align-items: center;
  position:relative;
  top:-30%;
  z-index:100000;
}
.pencil-edit{
  float:right;
    cursor:pointer;

}
.pencil-edi{
  position:relative;
  /* left:50px; */
  left:78%;
    cursor:pointer;

}
.compliance-tab{
  border-bottom:transparent !important;
}
/* .slick-list {margin: 0 -5px;} */
.slick-slide>div {padding: 0 5px;}
.requiredfields{
  color: red;
}
.requiredinputfields{
  border:1px solid #ff0000
}
.shift-right-icon{
  float:right;
  cursor:pointer;
}
.turnOver-table td{
  width:18% !important;
  font-size:13px;
  text-align:left;
}
.required-fontRight p{
  font-weight: 600;
 
}
.required-fontRight span{
  /* color:blue; */
  margin-left:10px;
}
.required-fontRight {
  font-weight: 600;
 
}
.required-fontRight2 {
  font-weight: 600;
  margin-right:4px;
 
}
.required-lineHeight{
  line-height:2;
}
.businessTable td {
  text-align:left;
}
.requiredMargin-Carousel{
  margin-bottom:30px;
}
.create-contactPlus{
  position:relative;
  left:65%;
  cursor:pointer;
}
.Update-contactEdit{
  position:relative;
  left:70%;
  cursor:pointer;
}
.create-contactPlus2{
  position:relative;
  left:85%;
  cursor:pointer;
}
.Update-contactEdit2{
  position:relative;
  left:85%;
  cursor:pointer;
}
.no-dataClass{
  text-align: center;
}
.no-dataClass2{
  text-align: center;
  position:relative;
  top:40%;
}
.nomeeting{
  text-align: center;
    padding-top: 40px;
    color: rgb(114, 49, 12);
    word-spacing: 3px;
    letter-spacing: 1px;
}
.viewhistory{
  position:relative;
  font-size:16px;
  float:right;
 right:3%;
 top:5px;
 letter-spacing: 1px;
}