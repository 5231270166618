.ClickableTab{
    background-color:#DCDCDC;
    border-radius: 5px ;
   
}
.ClickableTab:hover{
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.4);

}

.Card-container{

    width:100%;
    margin-left:40px;
    margin-right:10px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.4), 
    0px 4px 6px rgba(0, 0, 0, 0.3);
    margin-top:20px;


}

i.milkIcon {
    display: inline-block;
    width: 50px;
    height: 62px;
    max-width: 47px;
    min-width: 43px;
    min-height: 30px;
    max-height: 41px;
    background-image: url("../img/canns.png");
    background-repeat: no-repeat;
    background-size: 30px 30px;
    position:relative;
    left:15px;
  }

  i.dispatchIcon {
    display: inline-block;
    
}

.dispatchContainer {
    display: inline-block;
  }
  
  i.dispatchIcon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 50px;
    height: 62px;
    max-width: 42px;
    min-width: 43px;
    min-height: 32px;
    max-height: 26px;
    background-image: url(../img/dispatch.png);
    background-repeat: no-repeat;
    background-size: 30px 30px;
    position: relative;
    left: 20px;
  }
  
  .farmerListHeading {
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
    margin-top:35px;
  }
  

  .headingContainer {
    /* Additional styling can go here if needed */
  }
  
  .farmerListHeading {
    display: inline-block;
    font-size: 24px;
    margin: 0;
    margin-left: 10px;
    line-height: 1;
    vertical-align: middle;
  }
  
  .milkIcon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px; /* Space between icon and text */
  }


  .customSuccessButton {
    background-color:#A0D683; /* Updated green color */
    color: #72310c;
    border: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for hover effect */
    max-width: 15%;
    margin-bottom:10px;
    padding:5px;
    font-size: medium;
    border-radius: 5px;
    margin-left: 30px;

  }
  
  .customSuccessButton:hover {
    
    background-color: #72310c;
    color: white;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow for "pop out" effect */
  }

  .milkButton{
    width:20%;
  }
  

  .custom:hover{
    background-color:#A0D683;
    color:white;

  }

  .pagination{
    margin-left: 60%;
    margin-bottom: 5%;
    padding : 3px 4px;
   
  
   }
    .pagination button {
      background-color: rgb(163, 198, 20);
      color:#ffffff;
      font-weight: bold;
      padding: 1px 2px;
     
  

  border-radius: 4px;
      
      margin-left: 1%;
      transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
    }
    


    .pagination button:hover{
   
  
      color:#72310c;
     border:aliceblue;
  
     transform: scale(1.1); /* Slightly enlarges the button */
     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow for the pop-out effect */
  
    }

    .total{
      display: flex;
      float: right;
      justify-content: right;

    

    }
  
  