.footer {
    /*margin-bottom: -8px !important;
    position: fixed;
    margin-top: -44px;
    left: 0;
    bottom: 0;
    width: 100%;
     background-color: red !important;
    color: #000000;
    text-align: center;
    z-index: 10000;
    height: 44px !important;*/
    font-weight: bold;
    position: fixed;
    bottom: 0;
    background: none;
    color: #000;
}
.footer p {
    float: left;
    line-height: 0;
    margin-right: 10px;
    display: none;
}