
.mainActivityLoaderWrap{
    width: 100%;
    height: 120px;
    border: solid 1px #555;
    border-radius: 5px;
    padding: 0px;
    /*background-color: #a3c614;*/
    background-color: #efefef;
    margin-top: 20px;
    border:solid 1px #555;
    box-shadow: 2px 2px 8px #444;
}
.mainActivityLoaderWrap > span.spinner{
    position: absolute;
    left: 48%;
    top: 45%;
}
.mainActivityLoaderWrap > span.agroMessageSpan{
    position: absolute;
    left: 25%;
    top: 45%;
}

.rec-carousel-wrapper {
    width: 100% !important;
    margin-top: 20px;     
}
.rec-carousel-item {
    width: 250px;
    height: 120px;
}
.rec-item-wrapper {
    width: 250px !important;
    height: 120px !important;
    color: #333;
}
.rec-item-wrapper:hover{
    cursor: pointer;
    /* background-color: #72310c; */
    background-color: #a3c614;
    border-radius:5px;
    color: #72310c !important;
    box-shadow: inset 0px 0px 1px 3px #9c8d85;
    transition: 0.4s;
}
.MainActivityHolder{
    width: 250px;
    height: 120px;
}
.disableElement{
    width: 250px;
    height: 120px;
    background-color: #999;
    color: #222;
    opacity: 0.5;
}
.selectedMasterActivity{
    background-color: #a3c614;
    border-radius:5px;
    color: #72310c !important;
    box-shadow: inset 0px 0px 1px 3px #9c8d85;
}
.margin-left45 {
    margin-left: 45px;    
}
.agro-img-card2{
    width: 50px;
    height: 50px;
}
.MuiTableHead-root {
    zoom: 0.9;
}
.agro-mainBlock {    
    padding: 10px;
}
.rec-carousel-item-0 {
    background-color: rgba(114, 49, 12, 1);
}
.rec-carousel-item {
    /*background: #c8cac0;*/
    background-color:#efefef;
}
.text-center {
    text-align: center;
}
.text-center span{
    /*color: rgba(114, 49, 12, 1);*/
    color: #444;
}
.rec-pagination {
    display: none !important;
}

.AgroMainWrap{
    width: 95%;
    margin: 0 auto;
}

.AgroActivityCarousel{
    border: solid 1px #888;
    border-radius: 5px;
    padding: 0px 5px 0px 5px;
    background-color: #efefef;
    box-shadow: 2px 2px 8px #444;
}
.individualActivitiesCards{
    width: 100%;    
}
.verticalSeperatorLine{
    width:1px;
    display: inline-block;
    border: 2px solid #888;
    border:solid 1px #555;
    
}
.activityCardWrap{
    display: inline-block;
    width: 98%;
    margin: 0 auto;
}

.selectedActivityDetailsPaneHeader > h3{
    font-size: 1.4rem;

}
.selActivityTabLoader{
    position: absolute;
    left: 45%;
    top: 45%;
    width: 75px;
    height: 75px;
}
.selActivityTabLoaderWrap{
    position: relative;
    height: 250px;
}
.activeToggle{
    background-color: #0069d9;
    border-color: #005cbf;
    border: 1px solid transparent;
    color: #efefef;
}
.cropimage img:hover{
    overflow:hidden;
    /* transform:scale(1.5); */
    -ms-transform: scale(1.3); /* IE 9 */
    -webkit-transform: scale(1.3); /* Safari 3-8 */
    transform: scale(1.3); 
  }
  .cropimage{
    overflow: hidden;
    border-radius: 25px;
  }
  .cropimage img {
    border-radius: 20px;  
    object-fit: cover;
    transition: 0.5s all ease-in-out;
  }