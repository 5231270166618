.mainWeatherWidgetContainer {
	border: solid 1px rgba(163, 198, 20, 1);
	padding: 2px;
	box-shadow: 2px 2px 6px #555;
	/* // width: 80%; */
	width: 98%;
	/* // height: 495px; */
	 height:97%;
	position: relative;
	margin: auto;
	background-color: #E6E6E6;
	
}

.dashBoardWrap {
	height: 200px;
}

.weatherWidgetContainer {
	position: relative;
	/* display: inline-block; */
	width: 99%;
	margin:  auto;
	/* // height: 92px; */
	height: 110px;
	margin-top: 2px;
	border-right: solid 1px #fff;
	
}

.weatherValHolder {
	position: relative;
	height: 100%;
	z-index: 1;
	color: #fff;
	width: 100%;
}

.weatherValHolder>h3 {
	margin: 5px 5px 0px 0px;
	font-family: "quicksand_bold" !important;
	float: right;
	
}

.weatherDate {
	position: absolute;
	bottom: 0;
	right: 10px;
	font-size: 14px;
}

.weatherAlert {
	position: absolute;
	/* // bottom: 65px; */
	/* // bottom: 20px; */
	/* // right: 10px; */
	padding: 0px 0px 0px 5px;
	
}

.weatherValHolder>p {
	/* // margin: 10px 10px 0px 0px; */
	z-index: 100;
	float: right;
	font-weight: bold;
	/* // margin: 16px 10px 0px 0px; */
	margin: 34px 8px 0px 0px;
}

.weatherValHolder>span {
	font-weight: strong;
}

.slideWeatherWidget {
	cursor: pointer;
	padding: 5px;
	font-size: 1.3em;
	display: none;
}

.forecastField {
	width: 100%;
	height: 99%;
	position: relative;
	display: inline-block;
}

.forecastPresentDayIcon>div {
	display: inline-block;
}

@media (min-width: 360px) {}

@media (min-width: 576px) {}



.forecastDefault {
	background-color: #E6E6E6;
}

/* Sunny */
.forecastSunnyContainer {
	background-color: #78CFDE;
}

/* LightRain */
.forecastCloudyContainer {
	background-color: #71A6D1;
}

/* .forecastCloudyContainer {
	background-color: #90e0ef;
} */

/* MediunRain */
.forecastRainyContainer {
	background-color: #005075;
	overflow:hidden;
}

.forecastThunderStormContainer {
	color: #999;
	background-color: #303839;
	overflow: hidden;
}

/* HeavyRain */
.forecastStarryContainer {
	background-color: #06385D;
}

/* SUNNY */
.sunny {
	animation: sunny 15s linear infinite;
	background: linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
	height: 100px;
	/* // width: 20px; */
	margin-left: -15px;
	position: absolute;
	left: 80px;
	top: 0px;
}

.sunny:before {
	background: linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
	content: '';
	height: 150px;
	width: 20px;
	opacity: 1;
	position: absolute;
	bottom: 0px;
	left: 0px;
	transform: rotate(90deg);
}

.sunny:after {
	background: #FFEE44;
	border-radius: 50%;
	box-shadow: rgba(255, 255, 0, 0.2) 0 0 0 15px;
	content: '';
	height: 55px;
	width: 55px;
	position: absolute;
	left: -30px;
	top: 30px;
}

@keyframes sunny {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* CLOUDY */
.cloudy {
	animation: cloudy 5s ease-in-out infinite;
	background: #FFFFFF;
	border-radius: 50%;
	box-shadow:
		#FFFFFF 65px -15px 0 -5px,
		#FFFFFF 25px -25px,
		#FFFFFF 30px 10px,
		#FFFFFF 60px 15px 0 -10px,
		#FFFFFF 85px 5px 0 -5px;
	height: 50px;
	width: 50px;
	/*margin-left: -60px;*/
	position: absolute;
	top: 50px;
	left: 20px;
}

.cloudy:after {
	animation: cloudy_shadow 5s ease-in-out infinite;
	background: #000000;
	border-radius: 50%;
	content: '';
	height: 10px;
	width: 80px;
	opacity: 0.3;
	position: absolute;
	left: 5px;
	bottom: -30px;
	transform: scale(1);
}

@keyframes cloudy {
	50% {
		transform: translateY(-20px);
	}
}

@keyframes cloudy_shadow {
	50% {
		transform: translateY(20px) scale(.7);
		opacity: .05;
	}
}

/* RAINY */
.rainy {
	animation: rainy 5s ease-in-out infinite 1s;
	background: #888;
	border-radius: 50%;
	box-shadow:
		#888 65px -15px 0 -5px,
		#888 25px -25px,
		#888 30px 10px,
		#888 60px 15px 0 -10px,
		#888 85px 5px 0 -5px;
	display: block;
	height: 50px;
	width: 50px;
	/*	margin-left: -60px;*/
	position: absolute;
	/* // top: 50px; */
	top:40px;
	left: 20px;
}

.rainy:after {
	animation: rainy_shadow 5s ease-in-out infinite 1s;
	background: #000000;
	border-radius: 50%;
	content: '';
	height: 15px;
	width: 120px;
	opacity: 0.2;
	position: absolute;
	left: 5px;
	bottom: -30px;
	transform: scale(1);
}

.rainy:before {
	animation: rainy_rain .7s infinite linear;
	content: '';
	background: #CCCCCC;
	border-radius: 50%;
	display: block;
	height: 6px;
	width: 3px;
	opacity: 0.3;
	transform: scale(.9);
}

@keyframes rainy {
	50% {
		transform: translateY(-20px);
	}
}

@keyframes rainy_shadow {
	50% {
		transform: translateY(20px) scale(.7);
		opacity: 0.05;
	}
}

@keyframes rainy_rain {
	0% {
		box-shadow:
			rgba(0, 0, 0, 0) 30px 30px,
			rgba(0, 0, 0, 0) 40px 40px,
			rgb(0, 0, 0) 50px 75px,
			rgb(7, 68, 199) 55px 50px,
			rgb(7, 68, 199) 70px 100px,
			rgb(7, 68, 199) 80px 95px,
			rgb(7, 68, 199) 110px 45px,
			rgb(7, 68, 199) 90px 35px;
	}

	25% {
		box-shadow:
			#000 30px 45px,
			#000 40px 60px,
			#000 50px 90px,
			rgb(7, 68, 199) 55px 65px,
			rgb(7, 68, 199) 70px 120px,
			rgb(7, 68, 199) 80px 120px,
			#000 110px 70px,
			#000 90px 60px;
	}

	26% {
		box-shadow:
			#000 30px 45px,
			#000 40px 60px,
			#000 50px 90px,
			#000 55px 65px,
			rgba(0, 0, 0, 0) 70px 40px,
			rgba(0, 0, 0, 0) 80px 20px,
			#000 110px 70px,
			#000 90px 60px;
	}

	50% {
		box-shadow:
			#000 30px 70px,
			#000 40px 80px,
			rgba(0, 0, 0, 0) 50px 100px,
			#000 55px 80px,
			#000 70px 60px,
			#000 80px 45px,
			#000 110px 95px,
			#000 90px 85px;
	}

	51% {
		box-shadow:
			#000 30px 70px,
			#000 40px 80px,
			rgba(0, 0, 0, 0) 50px 45px,
			#000 55px 80px,
			#000 70px 60px,
			#000 80px 45px,
			#000 110px 95px,
			#000 90px 85px;
	}

	75% {
		box-shadow:
			#000 30px 95px,
			#000 40px 100px,
			#000 50px 60px,
			rgba(0, 0, 0, 0) 55px 95px,
			#000 70px 80px,
			#000 80px 70px,
			rgba(0, 0, 0, 0) 110px 120px,
			rgba(0, 0, 0, 0) 90px 110px;
	}

	76% {
		box-shadow:
			#000 30px 95px,
			#000 40px 100px,
			#000 50px 60px,
			rgba(0, 0, 0, 0) 55px 35px,
			#000 70px 80px,
			#000 80px 70px,
			rgba(0, 0, 0, 0) 110px 25px,
			rgba(0, 0, 0, 0) 90px 15px;
	}

	100% {
		box-shadow:
			rgba(0, 0, 0, 0) 30px 120px,
			rgba(0, 0, 0, 0) 40px 120px,
			#000 50px 75px,
			#000 55px 50px,
			#000 70px 100px,
			#000 80px 95px,
			#000 110px 45px,
			#000 90px 35px;
	}
}


/* RAINBOW */
.rainbow {
	animation: rainbow 5s ease-in-out infinite;
	border-radius: 170px 0 0 0;
	box-shadow:
		#FB323C -2px -2px 0 1px,
		#F99716 -4px -4px 0 3px,
		#FEE124 -6px -6px 0 5px,
		#AFDF2E -8px -8px 0 7px,
		#6AD7F8 -10px -10px 0 9px,
		#60B1F5 -12px -12px 0 11px,
		#A3459B -14px -14px 0 13px;
	height: 70px;
	width: 70px;
	margin-left: -40px;
	position: absolute;
	left: 80px;
	top: 30px;
	transform: rotate(40deg);
}

.rainbow:after {
	animation: rainbow_shadow 5s ease-in-out infinite;
	background: #000000;
	border-radius: 50%;
	content: '';
	opacity: 0.2;
	height: 15px;
	width: 120px;
	position: absolute;
	bottom: -23px;
	left: 17px;
	transform: rotate(-40deg);
	transform-origin: 50% 50%;
}

@keyframes rainbow {
	50% {
		transform: rotate(50deg);
	}
}

@keyframes rainbow_shadow {
	50% {
		transform: rotate(-50deg) translate(10px) scale(.7);
		opacity: 0.05;
	}
}

/* STARRY */
.starry {
	animation: starry_star 5s ease-in-out infinite;
	background: #fff;
	border-radius: 50%;
	box-shadow:
		#FFFFFF 26px 7px 0 -1px,
		rgba(255, 255, 255, 0.1) -36px -19px 0 -1px,
		rgba(255, 255, 255, 0.1) -51px -34px 0 -1px,
		#FFFFFF -52px -62px 0 -1px,
		#FFFFFF 14px -37px,
		rgba(255, 255, 255, 0.1) 41px -19px,
		#FFFFFF 34px -50px,
		rgba(255, 255, 255, 0.1) 14px -71px 0 -1px,
		#FFFFFF 64px -21px 0 -1px,
		rgba(255, 255, 255, 0.1) 32px -85px 0 -1px,
		#FFFFFF 64px -90px,
		rgba(255, 255, 255, 0.1) 60px -67px 0 -1px,
		#FFFFFF 34px -127px,
		rgba(255, 255, 255, 0.1) -26px -103px 0 -1px;
	height: 4px;
	width: 4px;
	opacity: 1;
	position: absolute;
	left: 75px;
	top: 120px;
}

.starry:after {
	animation: starry 5s ease-in-out infinite;
	border-radius: 50%;
	box-shadow: #FFFFFF -25px 0;
	content: '';
	height: 100px;
	width: 100px;
	position: absolute;
	top: -106px;
	transform: rotate(-5deg);
	transform-origin: 0 50%;
}

@keyframes starry {
	50% {
		transform: rotate(10deg);
	}
}

@keyframes starry_star {
	50% {
		box-shadow:
			rgba(255, 255, 255, 0.1) 26px 7px 0 -1px,
			#FFFFFF -36px -19px 0 -1px,
			#FFFFFF -51px -34px 0 -1px,
			rgba(255, 255, 255, 0.1) -52px -62px 0 -1px,
			rgba(255, 255, 255, 0.1) 14px -37px,
			#FFFFFF 41px -19px,
			rgba(255, 255, 255, 0.1) 34px -50px,
			#FFFFFF 14px -71px 0 -1px,
			rgba(255, 255, 255, 0.1) 64px -21px 0 -1px,
			#FFFFFF 32px -85px 0 -1px,
			rgba(255, 255, 255, 0.1) 64px -90px,
			#FFFFFF 60px -67px 0 -1px,
			rgba(255, 255, 255, 0.1) 34px -127px,
			#FFFFFF -26px -103px 0 -1px;
	}
}

/* STORMY */
.stormy {
	animation: stormy 5s ease-in-out infinite;
	background: #222222;
	border-radius: 50%;
	box-shadow:
		#222222 65px -15px 0 -5px,
		#222222 25px -25px,
		#222222 30px 10px,
		#222222 60px 15px 0 -10px,
		#222222 85px 5px 0 -5px;
	height: 50px;
	width: 50px;
	/*margin-left: -60px;*/
	position: absolute;
	/* // top: 50px; */
	top: 40px;
	left: 20px;
}

.stormy:after {
	animation: stormy_shadow 5s ease-in-out infinite;
	background: #000;
	border-radius: 50%;
	content: '';
	height: 15px;
	width: 120px;
	opacity: 0.2;
	position: absolute;
	left: 5px;
	bottom: -30px;
	transform: scale(1);
}

.stormy:before {
	animation: stormy_thunder 2s steps(1, end) infinite;
	border-left: 0px solid transparent;
	border-right: 7px solid transparent;
	border-top: 43px solid yellow;
	box-shadow: yellow -7px -32px;
	content: '';
	display: block;
	height: 0;
	width: 0;
	position: absolute;
	left: 57px;
	top: 70px;
	transform: rotate(14deg);
	transform-origin: 50% -60px;
}

@keyframes stormy {
	50% {
		transform: translateY(-20px);
	}
}

@keyframes stormy_shadow {
	50% {
		transform: translateY(20px) scale(.7);
		opacity: 0.05;
	}
}

@keyframes stormy_thunder {
	0% {
		transform: rotate(20deg);
		opacity: 1;
	}

	5% {
		transform: rotate(-34deg);
		opacity: 1;
	}

	10% {
		transform: rotate(0deg);
		opacity: 1;
	}

	15% {
		transform: rotate(-34deg);
		opacity: 0;
	}
}

/* SNOWY */
.snowy {
	animation: snowy 5s ease-in-out infinite 1s;
	background: #FFFFFF;
	border-radius: 50%;
	box-shadow:
		#FFFFFF 65px -15px 0 -5px,
		#FFFFFF 25px -25px,
		#FFFFFF 30px 10px,
		#FFFFFF 60px 15px 0 -10px,
		#FFFFFF 85px 5px 0 -5px;
	display: block;
	height: 50px;
	width: 50px;
	margin-left: -60px;
	position: absolute;
	left: 1112px;
	top: 70px;
}

.snowy:after {
	animation: snowy_shadow 5s ease-in-out infinite 1s;
	background: #000000;
	border-radius: 50%;
	content: '';
	height: 15px;
	width: 120px;
	opacity: 0.2;
	position: absolute;
	left: 8px;
	bottom: -60px;
	transform: scale(1);
}

.snowy:before {
	animation: snowy_snow 2s infinite linear;
	content: '';
	border-radius: 50%;
	display: block;
	height: 7px;
	width: 7px;
	opacity: 0.8;
	transform: scale(.9);
}

@keyframes snowy {
	50% {
		transform: translateY(-20px);
	}
}

@keyframes snowy_shadow {
	50% {
		transform: translateY(20px) scale(.7);
		opacity: 0.05;
	}
}

@keyframes snowy_snow {
	0% {
		box-shadow:
			rgba(238, 238, 238, 0) 30px 30px,
			rgba(238, 238, 238, 0) 40px 40px,
			#EEEEEE 50px 75px,
			#EEEEEE 55px 50px,
			#EEEEEE 70px 100px,
			#EEEEEE 80px 95px,
			#EEEEEE 110px 45px,
			#EEEEEE 90px 35px;
	}

	25% {
		box-shadow:
			#EEEEEE 30px 45px,
			#EEEEEE 40px 60px,
			#EEEEEE 50px 90px,
			#EEEEEE 55px 65px,
			rgba(238, 238, 238, 0) 70px 120px,
			rgba(238, 238, 238, 0) 80px 120px,
			#EEEEEE 110px 70px,
			#EEEEEE 90px 60px;
	}

	26% {
		box-shadow:
			#EEEEEE 30px 45px,
			#EEEEEE 40px 60px,
			#EEEEEE 50px 90px,
			#EEEEEE 55px 65px,
			rgba(238, 238, 238, 0) 70px 40px,
			rgba(238, 238, 238, 0) 80px 20px,
			#EEEEEE 110px 70px,
			#EEEEEE 90px 60px;
	}

	50% {
		box-shadow:
			#EEEEEE 30px 70px,
			#EEEEEE 40px 80px,
			rgba(238, 238, 238, 0) 50px 100px,
			#EEEEEE 55px 80px,
			#EEEEEE 70px 60px,
			#EEEEEE 80px 45px,
			#EEEEEE 110px 95px,
			#EEEEEE 90px 85px;
	}

	51% {
		box-shadow:
			#EEEEEE 30px 70px,
			#EEEEEE 40px 80px,
			rgba(238, 238, 238, 0) 50px 45px,
			#EEEEEE 55px 80px,
			#EEEEEE 70px 60px,
			#EEEEEE 80px 45px,
			#EEEEEE 110px 95px,
			#EEEEEE 90px 85px;
	}

	75% {
		box-shadow:
			#EEEEEE 30px 95px,
			#EEEEEE 40px 100px,
			#EEEEEE 50px 60px,
			rgba(238, 238, 238, 0) 55px 95px,
			#EEEEEE 70px 80px,
			#EEEEEE 80px 70px,
			rgba(238, 238, 238, 0) 110px 120px,
			rgba(238, 238, 238, 0) 90px 110px;
	}

	76% {
		box-shadow:
			#EEEEEE 30px 95px,
			#EEEEEE 40px 100px,
			#EEEEEE 50px 60px,
			rgba(238, 238, 238, 0) 55px 35px,
			#EEEEEE 70px 80px,
			#EEEEEE 80px 70px,
			rgba(238, 238, 238, 0) 110px 25px,
			rgba(238, 238, 238, 0) 90px 15px;
	}

	100% {
		box-shadow:
			rgba(238, 238, 238, 0) 30px 120px,
			rgba(238, 238, 238, 0) 40px 120px,
			#EEEEEE 50px 75px,
			#EEEEEE 55px 50px,
			#EEEEEE 70px 100px,
			#EEEEEE 80px 95px,
			#EEEEEE 110px 45px,
			#EEEEEE 90px 35px;
	}
}