.customSuccessButton {
  background-color:#A0D683; /* Updated green color */
  color: #72310c;
  border: none;
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for hover effect */
  max-width: 15%;
  margin-bottom:10px;
  padding:5px;
  font-size: medium;
  border-radius: 5px;
  margin-left: 30px;

}

.customSuccessButton:hover {
  
  background-color: #72310c;
  color: white;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow for "pop out" effect */
}


.pagination{
  margin-left: 60%;
  margin-bottom: 5%;
  padding: 3px 4px;
  padding-bottom: 5%;

 }
  .pagination button {
    background-color: rgb(163, 198, 20);
    color:#ffffff;
    font-weight: bold;
   

padding: 3px 4px;
border-radius: 4px;
    
    margin-left: 1%;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
  }

  .pagination button:hover{
   
  
    color:#72310c;
   border:aliceblue;

   transform: scale(1.1); /* Slightly enlarges the button */
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow for the pop-out effect */

  }


  .pagination-2 button {
    background-color: rgb(163, 198, 20);
    color:#72310c;
    font-weight: bold;
   

padding: 3px 4px;
border-radius: 4px;
    
    margin-left: 1%;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */


  }

  .pagination-2 button:hover{
    color:#72310c;
    border:aliceblue;
 
    transform: scale(1.1); /* Slightly enlarges the button */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds a shadow for the pop-out effect */
 
   }

   .total{
     display: flex;
     justify-content: right;
     align-items: right;
     float: right;
     margin-right: 180px;
     font-size: large;


   }
   .Profithover {
    position: relative;
    cursor: pointer; 
    color: white;
  }
  
  .Profithover::after {
    content: "Dispatch Amount - (Collection Amount + Transport Cost + Agent Commission) ";
    position: absolute;
    bottom: 100%; 
    left: 50%;
    transform: translateX(-90%);
    background-color: #333;
    color: white; 
    padding: 10px 10px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0; 
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
  }
  
  .Profithover:hover::after {
    opacity: 1;
    visibility: visible; 
  }