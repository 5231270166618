.rcv-btn-one-custom {
  background-color: rgba(163, 198, 20, 1);
  border: solid 2px rgba(114, 49, 12, 1);
  width: 25%;
  height: 20%;
  padding: 0;

  /*position:200px;*/
}
.rcv-btn-two-custom {
  background-color: rgba(163, 198, 20, 1);
  border: solid 2px rgb(92, 72, 61);
  width: 100%;
}
.inputProductHeader{
  width: 95%;
  margin: 0px auto;

}
upper-container {
  width: 100px;
  height: 200px;
}

/* my css */
.crops-button {
  background-color: rgba(163, 198, 20, 1);
  border: transparent;
  width: 80px;
  position: absolute;
  bottom: 0;
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 0px;
}
.product-category {
  font-size: 18px;
  font-weight: 600;
}
.product-subcategory {
  color: rgba(114, 49, 12, 1);
  font-size: 17px;
  font-weight: 600;

}
.wrapping-button {
  display: flex;

  justify-content: center;
}
.crops-button:hover {
  background-color: purple;
}
/* .wrapp {
  width: 90%;
  position: absolute;
  bottom: 0;
  padding: 8px;
} */

.crop-discount {
  /* color: #007bff; */
  color:#FF5403;
   /* color:#a3c614; */
  /* color:#f97700;  */
  font-weight: 600;
  font-size: 15px;
}

.rcv-card-content {
  display: block;
}
.card-content-height {
  word-wrap: break-word;
  letter-spacing: 1px;
  margin-left: 8px;
  padding-top:10px;
  font-family: Arial, Helvetica, sans-serif;
}
.view-order-button {
  color: white;
  margin-left: 5px;
  padding: 7px;
  background-color: rgba(163, 198, 20, 1);
}
.view-order-button:hover {
  color: white;
  background-color: purple;

}
.cardHeight {
  /* box-shadow: inset 2px 2px 24px rgb(182, 176, 176); */
  /* rgb(197, 189, 189); */
  /* height: calc(100vh / 1.5); */
  /* height:420px; */
  height:438px;

  /* height: calc(100vh / 1.3); */
  border-radius: 25px;
  position: relative;
}
.crop-image img {
  border-radius: 20px;
  height: 170px;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
  /* border: 1px solid rgb(182, 176, 176); */
}
.shift-right {
  float: right;
}
.inputPadding {
  padding: 8px;
  border-radius: 20px;
  outline: none;
  /* box-shadow: inset 2px 2px 13px rgb(197, 189, 189); */
  border-color: rgba(114, 49, 12, 1);
}
.inputPadding:hover {
  /* box-shadow: 2px 2px 15px 0px #777; */
  cursor: pointer;
}

.formWeight {
  font-weight: 600;
  font-size: 14px;
}
.quantity-Amount {
  font-weight: 600;
  color: rgba(114, 49, 12, 1);
}
@media only screen and (min-device-width: 1368px) and (max-device-width: 1920px) {
  .cardHeight {
    /* height: calc(100vh / 1.85); */
    /* height: calc(100vh / 1.6); */
    /* height:420px; */
    height:438px;

  }

  .crops-button {
    padding: 0px;
  }
}
@media only screen and (max-width: 1024px) {
  .cardHeight {
    height:380px;
    /* height: calc(100vh / 3.3); */
  }
  .quantity-Amount {
    font-size: 14px;
  }
  .quantity-A {
    font-size: 14px;
  }
  .crop-image img {
    height: 120px;
  }
  .product-category {
    font-size: 15px;
  }
  .product-subcategory {
    font-size: 15px;
  }
  .crop-discount {
    font-size: 12px;
  }
  .crops-button {
    width: 40px;
    padding: 0px;
  }
}

@media screen and (max-width: 768px) {
  .cardHeight {
     height:360px;
    /* height: calc(100vh / 2.6); */
  }
  .crop-button {
    padding: 0px;
  }
}
@media screen and (max-width: 600px) {
  .cardHeight {
    height: auto;
  }

  .crops-button {
    position: relative;
  }
}

.inputOrderHeading {
  width: 70% !important;
}

.typeDropdown {
  margin-top: 13px;
}

.requiredfields{
  color: red;
}
.requiredinputfields{
  border:1px solid #ff0000
}

.QuantityOrdered {
  background-image: url("../img/quantity.svg");
}

.TotalPrice {
  background-image: url("../img/total_price.svg");
}

.OfferedPrice {
  background-image: url("../img/offer_price.svg");
}
.no-dataRenderproduct{
  position: absolute;
    left: 45%;
    margin-top: 50px;


}
.crop-image{
  overflow: hidden;
  border-radius: 25px;
  /* transition: transform .2s; */
}
.crop-image img:hover{
  overflow:hidden;
  /* transform:scale(1.5); */
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Safari 3-8 */
  transform: scale(1.3); 
}
.shadowContainer {
  position: relative;
  transition: 5s;
}
.shadow-content,
.shadow-content2,
.shadowContainer:after {
  position: absolute;
  left: 0px;
  right: 0px;
}

.shadowContainer:after {
  content: "";
  display: block;
  border-radius: 30px;
  width:90%;
  /* width:100%; */
  /* top:200px; */
  left:15px;
  background: rgba(163, 198, 20, 0.1);
  /* background: rgba(176, 185, 140, 0.1); */
  /* background: rgba(221, 11, 133, 0.1); */


  transition: 0.2s;
  transform: rotate3d(-1, 1, 0, 120deg);
  top: 0px;
  bottom: 0px;
  opacity: 0;
}
.shadowContainer:hover:after {
  opacity: 0.8;
  transform: rotate3d(0, 0, 0, 0deg);

  transition-delay: 0.1s;
}
.shadow-content {
  top: 45%;
  color: white;
  left: 2%;
  opacity: 0;
  z-index: 1;
  transform: translate(10%, -30%);
  transition: 0.3s;
  text-align: center;
}
.shadow-content2 {
  /* top: 50; */
  bottom:0;
  color: white;
  /* background-color: black; */
  width: 15%;
  /* left: 170; */
  opacity: 0;
  z-index: 1;
  transform: translate(10%, -30%);
  transition: 0.5s;
  /* text-align: center; */
  font-size: xx-large;
}

.shadowContainer:hover .shadow-content {
  opacity: 1;

  transform: translate(0, -50%);
  transition-delay: 0.3s;
}
.shadowContainer:hover .shadow-content2 {
  opacity: 1;

  transform: translate(0, -50%);
  transition-delay: 0.7s;
}
.crops-buttonWithAnimation {
  background-color: rgba(163, 198, 20, 1);
  border: transparent;
  width: 80px;
  height:35px;
  /* left:95px; */
  left:25px;

  position: absolute;
  bottom: 0;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 0px;
}
.crops-buttonWithAnimation:hover{
  /* background-color:#EE82EE;  */
  background-color:#7a7ddd; 

  
  
  
}
.hidingButton{
  /* visibility:hidden; */
  display:none;
}
.update-TextColor{
  /* color:#007bff; */
  color:rgba(114, 49, 12, 1);
  letter-spacing: 1px;
  /* margin-left:4px; */
 }
 .remarksposition{
   position:relative;
   left:20px;
 }
 .modalColor{
      /* color:#007bff;  */
      color:rgba(163, 198, 20, 1);
      font-weight:700;
      letter-spacing: 1px;
      position: relative;
     left: 10%;
     font-family: Arial, Helvetica, sans-serif;

  /* box-shadow: inset 10px 10px 50px #a3c614; */

 }
 .GreenMessageText{
   color:green;
   font-family:Arial, Helvetica, sans-serif;
   font-weight: 600;
 }
 .RedMessageText{
  color:red;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: 600;


}
.OrangeMessageText{
  color:orange;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: 600;


}
.BlueMessageText{
  /* color:#23629F; */
  color:rgba(163, 198, 20, 1);
  font-family:Arial, Helvetica, sans-serif;
  font-weight: 600;


}
.buttonBackgroundColor{
  background-color:rgba(163, 198, 20, 1) ;
  font-family:Arial, Helvetica, sans-serif;
  border-radius: 17px;
    border-color: white;

}
.buttonBackgroundColor:hover{
  background-color:rgb(185, 103, 103);
  border-color:rgb(185, 103, 103);
}
.FontFamilyStyle{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* font-family: Arial, Helvetica, sans-serif; */
}
.varietyText{
  font-weight: 600;
}
.businessIcon1{
  position: relative;
   right: 39%;
   /* top:-8px; */
}
.businessIcon2{
  position: relative;
   /* right: 18%; */
   right: 15%;

   /* top:-8px; */
}
.CropMainCardsIcon {
  display: inline-block;
  position: absolute;
  /* left: 30px; */
  /* left: 26px; */
 
  left: 44px;
  top: 18px;
  width: 250px;
  height: 55px;
  background-size: 45px 45px;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.CattleOwnedIcon {
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 10px;
  width: 55px;
  height: 55px;
  background-image: url("../img/cattle_feed.png");
  background-size: 50px 50px, 30px 30px;
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  opacity: 0.8;
}
.CropIcon {
  background-image: url("../img/crops_plant.png");
}
.ProcurementOwnedIcon {
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 10px;
  width: 55px;
  height: 55px;
  background-image: url("../img/commodity.png");
  background-size: 50px 50px, 30px 30px;
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  opacity: 0.8;
}
.inputProductIcon {
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 10px;
  width: 55px;
  height: 55px;
  background-image: url("../img/product.png");
  background-size: 50px 50px, 30px 30px;
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  opacity: 0.8;
}
.productBoButton {
  --c: rgba(163, 198, 20, 1);
  color: rgb(114, 49, 12);
  font-size: 13px;
  border: 0.3em solid var(--c);
  border-radius: 0.5em;
  /* width: 18em; */
  height: 3em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  /* line-height: 3em; */
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  background-color:#e9ecef ;
  /* margin: 1em; */
}
.productBoButton span {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}
.productBoButton:hover {
  color: white;
  box-shadow: 2px 2px 8px #444;
}
.productBoButton:hover span {
  transform: translateY(0) scale(2);
}
.productBoButton span:nth-child(1) {
  --n: 1;
}
.productBoButton span:nth-child(2) {
  --n: 2;
}
.productBoButton span:nth-child(3) {
  --n: 3;
}
.productBoButton span:nth-child(4) {
  --n: 4;
}
.trialaccordion:hover{
  background-color: rgba(163, 198, 20, 0.7);
  color:#0056b3;
  letter-spacing: 1px;
}
.sellingTab{
  color: white;
 text-align: center;
 background-color: rgba(163, 198, 20, 0.7);
 padding: 15px;
 border-radius: 20px;
 font-size: 20px;
 outline:none;
 border:1px solid transparent;
}
.outerimg{
  overflow: hidden;
  
}
.outerimg img {
  border-radius: 20px;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
  overflow:hidden;
  /* max-width: 100%;
  object-fit: contain;
  overflow:hidden;
  transition: all .2s ease-in-out; 
  border-radius: 10px;
  cursor:pointer; */





}
.outerimg img:hover{
  overflow:hidden;
  -ms-transform: scale(1.8); /* IE 9 */
  -webkit-transform: scale(1.8); /* Safari 3-8 */
  transform: scale(1.8); 
}