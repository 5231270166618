.dashboardCard {
  border: solid 1px #a3c614;
  cursor: pointer;
  box-shadow: 0px 1px 5px 2px #777;
}
.dashboardCard:hover {
  box-shadow: 2px 2px 50px 0px #777;
}
.dashboardCard:hover > div.card-body > img {
  animation-name: dashboardIconAnimate;
  animation-duration: 1.5s;
}
.dashBoardIcons {
  height: 72px;
  /* width: 150px; */
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px auto !important;
  text-align: center;
  border: 0px solid #ccc;
}

.dashboardCardTitle {
  font-family: "karla_bold";
  font-size: 1.4rem;
}
.dashboardCardVals {
  font-family: "quicksand_bold";
  /* font-size: 1.7rem; */
   /* height: 65px;
  min-height: 64px;
  max-height: 66px; */
  font-size: 1.4rem;

  height: 72px;
  min-height: 72px;
  max-height: 73px;

  
}

.fpoCardVals {
  font-family: "quicksand_bold";
  font-size: 1.3rem;
  height: 65px;
  min-height: 64px;
  max-height: 66px;
}

.dashboardLoader {
  position: absolute;
  top: 100%;
  left: 45%;
  width: 80px;
  height: 80px;
}
.modal-adjust {
  margin-top: 40px !important;
  /* width: 200px; */
}

.future-prices {
  height: 40px;
  /* margin-top: 5px; */
   margin-top: 15px;
  font-size: 11px;
  bottom: 20px;
}

.first-block {
  background: #a3c614;
  height: 37px;
  line-height: 3;
  width: 15%;
}

.second-block {
  background: #edecea;
  height: 37px;
  line-height: 3;
  width: 38%;
  margin-left: -77px;
  padding-left: 7px;
}

.third-block {
  background: #a3c614;
  height: 37px;
  line-height: 3;
  width: 9%;
  margin-left: 22px;
   margin-left: 12px; 

}

.fourth-block {
  background: #edecea;
  height: 37px;
  line-height: 3;
  width: 40%;
  margin-left: -40px;
  
}

/** Key frames animation starts **/
@keyframes dashboardIconAnimate {
  from {
    width: 70px;
    height: 70px;
  }
  to {
    width: 100px;
    height: 100px;
  }
}

@-moz-keyframes dashboardIconAnimate {
  from {
    background-size: 70px 70px;
  }
  to {
    background-size: 100px 100px;
  }
}

@-webkit-keyframes dashboardIconAnimate {
  from {
    background-size: 70px 70px;
  }
  to {
    background-size: 100px 100px;
  }
}
@-o-keyframes dashboardIconAnimate {
  from {
    background-size: 70px 70px;
  }
  to {
    background-size: 100px 100px;
  }
}
/** Key frames animation ends **/
/* .marquee{
  margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;
   
    display: block !important;
}
.marquee span {
 
  display: inline-block;
    text-indent: 0;
    overflow: hidden;
    -webkit-transition: 45s;
    transition: 45s;
    -webkit-animation: marquee 45s linear infinite;
    animation: marquee 45s linear infinite;
} */

/* .marquee span:hover {
  animation-play-state: paused
} */


/* @keyframes marquee {
 
   0% { transform: translate(7%, 15%); }
  100% { transform: translate(-100%, 0); }
}

@media (prefers-reduced-motion: reduce) {
  .marquee span {
    animation-iteration-count: 1;
    animation-duration: 0.01; 
    
    width: auto;
    padding-left: 0;
  }
} */
/* .marquee2{
  margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;
   
    display: block !important;
} */
/* .marquee2 span {
  
  display: inline-block;
    text-indent: 0;
    overflow: hidden;
    -webkit-transition: 110s;
    transition: 110s;
    -webkit-animation: marquee 110s linear infinite;
    animation: marquee 110s linear infinite;
} */

/* .marquee2 span:hover {
  animation-play-state: paused
} */
/* @media (prefers-reduced-motion: reduce) {
  .marquee2 span {
    animation-iteration-count: 1;
    animation-duration: 0.01; 
    
    width: auto;
    padding-left: 0;
  }
} */
.inbuilt-padding{
  padding:1.55rem 1.25rem;
}
.fpofontHeight{
  /* font-size:19px; */
  font-size:17px;

}
/* .fpotextSize{
  font-size:24px;
} */
.finalChange{
  /* background-color: yellow; */
  /* width:100%; */
  /* background: #edecea; */
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
 
  display: block !important;
  display: inline-block;
  text-indent: 0;
  
}
/* .tablecolorrows:nth-child(odd){
   background-color: #f7ffc7;
}
.tablecolorrows:nth-child(even){
  background-color: #f3f7dd;
} */
.disableDataClass{
  pointer-events: none;
  opacity:0.2;
   width: 100%;
  padding: 10px 5px 10px 10px;
  position: relative;
  /* background-color: red; */
}
.nocommodityData{
  text-align: center;
  padding-top: 50px;
  color: rgb(114, 49, 12);
  /* word-spacing: 3px; */
  letter-spacing: 1px;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}
.pricesContainer{
  /* display:inline-block; */
  position:relative;
  top:20px;
  /* left:6%; */

  left:1%;
  bottom:20px;
  /* width:100%; */
}
.Pricefirst-block{
  background: #a3c614;
  height: 40px;
  line-height: 3;
  /* width: 15%; */
  width: 13%;
  display:inline-block;
  padding-left: 6px
}
.Pricesecond-block {
  background: #edecea;
  display:inline-block;
  height: 40px;
  line-height: 3;
  /* width: 35%; */
  width: 35%;
  padding-left: 6px;
  /* margin-left: -77px;
  padding-left: 7px; */
}

.Pricethird-block {
  background: #a3c614;
  display:inline-block;
  height: 40px;
  line-height: 3;
  /* width: 15%; */
  width: 13%;
  padding-left: 6px;
    margin-left: 7px
  /* margin-left: 22px;
   margin-left: 12px;  */
}

.Pricefourth-block {
  display:inline-block;
  background: #edecea;
  height: 40px;
  line-height: 3;
  /* width: 35%; */
  width: 38%;
  margin-left: -20px;
  padding-left: 6px;
}