.doordrishti {
  float: left;
  width: 40px;
  height: 40px;
  /* margin-left: 7px; */
  margin: 0px 25px 0px 10px !important;
  border: none;
  background: #ffffff;
  border-radius: 50%;
  padding: 3px !important;
}

.topNavBar {
  position: fixed;
  top: 0;
  /*margin: 0px 0px 0px -5px !important;*/
  width: 100%;
  box-shadow: 3px 3px 8px #777;
  -moz-box-shadow: 3px 3px 8px #777;
  -webkit-box-shadow: 3px 3px 8px #777;
  -o-box-shadow: 3px 3px 8px #777;
  z-index: 10000;
}

.userDropDownParent {
  position: absolute;
  right: 10px;
}

#sidebarToggle {
  display: block;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
}
#sidebarToggle:hover {
  background-image: url("../img/open-menu.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
}
.openSideBarMenu {
  background-image: url("../img/open-menu.png");
  margin: 0px 3px 0px 0px;
}
.closeSideBarMenu {
  background-image: url("../img/menu.png");
}

a.userIcon {
  color: #fff;
  font-size: 1.1rem;
}

h4.brandText {
  padding: 0 !important;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  color: #efefef;
}

li.fpoLocationHolder {
  padding: 10px 10px 0px;
  margin-top: 10px;
}
li.fpoLocationHolder > span {
  color: #fff;
}
.topBarNormalItems {
  visibility: visible;
}

.fpoDetsRespView {
  color: #444;
  padding: 5px 10px 5px 25px;
  display: none;
}
.navDropDownUserNameViewOnly {
  color: #444;
  padding: 5px 10px 5px 25px;
}
.navDropDownUserNameView {
  color: #444;
  padding: 5px 10px 5px 25px;
}
.navDropDownUserNameView:hover {
  background-color: #72310c;
  color: #a3c614;
}
.topNavBarDropDown {
  width: 200px;
}
.changePassword {
  cursor: pointer;
}
.passwordChangeSpinnerWrap {
  display: inline-block;
  position: absolute;
  left: 60%;
  z-index: 1;
}
.userName {
  color: white;
}
.userName:hover {
  color: white;
  text-decoration: underline;
}

@media (min-width: 360px) {
  .fpoDetailsRespView {
    display: block;
  }
  .topBarNormalItems {
    visibility: hidden;
  }
}
@media (min-width: 576px) {
  .fpoDetailsRespView {
    display: block;
  }
  .topBarNormalItems {
    visibility: hidden;
  }
}
@media (min-width: 768px) {
  .fpoDetailsRespView {
    display: hidden;
  }
  .topBarNormalItems {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .fpoDetailsRespView {
    display: hidden;
  }
  .topBarNormalItems {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .fpoDetailsRespView {
    display: hidden;
  }
  .topBarNormalItems {
    visibility: visible;
  }
}
li.bellHolder {
  padding: 15px 10px 0px;
}
li.bellHolder > span {
  color: rgb(241, 233, 233);
}
.cardbody{
  height:250px;
}
.dvaraWhiteText{
  color: #fafaf9;
}
.bellHolder::after{
  content: attr(current-count);
  color: rgb(114, 49, 12);
  position: relative;
  top:-40px;
  /* right: 350px; */
  left:15px;
  width:30px;
  height:25px;
  background-color: rgb(162 , 198 , 23);
  border-radius: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: x-small; */
 }
 .modalpopup{
  position: absolute;
  /* margin-top:-230px;   */
  width:580px;
  /* height: 720px; */
  /* left:70%;  */
  top: -240px; 
  object-fit: contain;
  background-color: white;
  }
 input{ 
  width:20px;
  height: 20px; 
 } 
 .notification-pop-up{  
   /* border-radius: 5px; */
   height:80px;
   width: auto;
   position: relative;
   top:-30px;
   background-color: rgb(255, 255, 255);
   box-shadow: 2px 4px 6px #8b6868 !important;

   color:black;  
   font-size: 12px;
   font-weight: 500;
   padding: 5px 5px 5px 100px;
   backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
   cursor: pointer;
   margin-top: 10px;
   word-wrap:normal;
   /* font-family: "varela_reg"; */
 }
 .reminder-pop-up{
  /* box-shadow: inset 10px 10px 50px rgb(209, 225, 157); */
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 4px 6px #156130 !important;
  border-radius: 5px;
  height: 80px;
  padding-left: 10px;
  color: #051df9;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
} 
.notification-message{
   color:rgb(114, 49, 12);
   font-size: 14px;
   font-weight: bold;
  }
.date-format{
   float:right;
   position: absolute;
   top: 48px;
   right: 10px;   
   font-size: 12px;
  color:rgb(114, 49, 12);
  }
  .notification-link{
    font-size: 13px;
    color:rgb(0, 0, 0);
    /* margin-top: 5px; */
  }
  .dateformat{
    float:right;
    color: black;
    font-size: 12px;
    position: relative;
    top:-20px;
    padding-right: 10px;
  }
  .Notification-title{
    margin-top:-40px;
    padding: 3px;
    position:relative;
    margin-left:60px;
    color: rgb(114 , 49 , 12);
    width:fit-content;
    font-family: "varela_reg";
    font-size: 23px;
   font-weight: bold;
  }
  .Nodata{
     height:50px;
    position: relative;
    top:45px;
    left:-3px;
    font-size: larger; 
    width: 95%;
    background-color: #ffff;
    border-radius: 3px;
    box-shadow: 2px 2px 8px rgb(182, 179, 179);
    padding: 10px;
  }
  .no-data{
    height:50px;
    position: absolute;
     /* top:15px; */
    left:73px; 
    font-size: larger; 
    width: 90%;
    background-color: #ffff;
    border-radius: 3px;
    box-shadow: 2px 2px 8px rgb(182, 179, 179);
    padding: 10px;
  }

  @media only screen and (min-width: 768px){
  .modalup{
    position: absolute;
    /* height:600px; */
    width:590px !important; 
    top: 40px;
    right: 20px;
    margin-top: 20px;   
    right: 1%;
    transform: translate(50%, 0) !important;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: calc(50% - 320px);
 } 
 }
 @media only screen and (min-width: 600px) {
  .modalup{
    /* height:200px; */
    width:590px !important; 
    top: 40px;
    right: 20px;
    margin-top: 20px;   
    right: 1%;
    background-color: white;
 } 
}

 .Notification-read{
    margin-top:-38px;
    padding: 3px;
    position:relative;
    margin-left:380px;
    color: rgb(114 , 49 , 12);
    width:fit-content;
    font-family: "varela_reg";
    /* font-size: 22px; */
   /* font-weight: bold; */
  }
  .anchor-link{
    margin-right:150px;
    /* font-size:18px;
    margin-bottom:5px; */ 
    color:rgb(114, 49, 12);
  }
  .anchor-link:hover{
    color: #2b89ed;
  }
  .anchor{
    color:black;
  }
  .anchor:hover {
    color: #2b89ed;
  }