.header-bg {
  background-color: #a3c614;
}

.headerComp th {
  text-align: center;
}
table td {
  width: 12%;
  text-align: center;
}
.brand_dropdown {
  width: 15% !important;
}
.brand_input {
  height: 15px;
}
.total_comp_amt {
  background-color: #341f1f4f !important;
}

.my-modal {
  width: 70vw; /* Occupy the 90% of the screen width */
  max-width: 90vw;
}

.hyplink {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.capitalise {
  text-transform: lowercase;
  text-transform: capitalize !important;
}
.farmerData {
  margin-left: 30px !important;
}
.hideBrand{
  display:none;
}
.ExportButtonFrontClass{

    background-color: #dfdfdf !important;
    /* color: #333 !important; */
    color: brown !important;

    font-size: 0.8rem !important;
    border-color: #0062cc;
}
