.farmerListMainSection{
  position: relative;
  width: 95%;
  margin: 0 auto;
  /* font-family:Arial, Helvetica, sans-serif */
}
.farmersHeader {
  width: 100%;
  padding: 10px 5px 10px 10px;
  position: relative;
  /* font-family:Arial, Helvetica, sans-serif */
}
.farmerCategoryToggle {
  color: #666 !important;
}
.toggleButtonElem {
  background-color: #dfdfdf;
  color: #333;
  font-size: 0.8rem;
}
.toggleButtonElemBorder{
  border-radius:7px;
  margin-right:3px;
}
.toggleButtonElem:hover {
  background-color: #a3c614 !important;
  color: #fff !important;
  text-shadow: 2px 2px 2px 8px #555;
  border-color:white;
}
.toggleButtonActive {
  background-color: #a3c614 !important;
  color: #fff !important;
  text-shadow: 2px 2px 2px 8px #555;
}

.farmerListHeading {
  /*font-family: "quicksand_bold";*/
  display: inline-block;
  padding: 5px 0px !important;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}
h6.farmerListHeading {
  /*font-family: "barlow_reg";*/
  font-weight: normal;
}
.farmerListCardHeading{
  display: inline-block;
  padding: 5px 0px !important;
  /* font-size: 1.3em; */
  font-size: 1.1em;
  margin: 0 auto;
}
h6.farmerListCardHeading{
  display: inline-block;
  font-size: 1em;
  padding: 5px 0px !important;
  margin: 0 auto;
  line-height: 1px;
}
.farmerCardNameDets{
  line-height: 5px;
  /* background-color: red; */
  position:relative;
  right:8%;
}
.farmerListSubHeading{
  display: inline-block;
  /* padding: 5px 0px !important; */
  font-weight: normal;
  /* font-size: 1.1rem; */
  font-size: 1.1rem;
}
.FarmersListPane {
  height: 100vh;
  position: relative;
}
.farmersTabContent {
  height: 500px;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: visible;
  border-top: solid 1px #666;
}
.farmersBottomTabContent {
  overflow-x: visible;
  overflow-y: hidden;
  border-top: solid 1px #666;
}
.farmersCard {
  padding: 5px 0px 5px 0px;
  margin: 5px 0px 0px 5px;
  background-color: #ebebeb;
  box-shadow: 2px 2px 8px #888;
}

.changeNumber{
  box-shadow: -2px -8px 6px #ed6415 !important;
}
.blackShadow{
  box-shadow: -2px -8px 6px black !important;
}
.nacf-Yes{
  box-shadow: -5px 8px 9px #3a0404 !important;
}

.farmerCardContent {
  display: inline-block;
  height: 100px;
}
.farmerCardTitle {
  width: 15%;
}
.farmerCardDetails {
  width: 80%;
}
.farmerCardSiteDetails {
  font-size: 0.9rem;
  line-height: 7px;
}
.farmersRegSiteBar {
  border: solid 1px #777;
  border-radius: 3px;
  margin: 15px 0px;
  width: 90%;
}
.farmerRegSiteBarStatus {
  background-color: #a3c614;
}
i.frIcon {
  display: inline-block;
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  min-height: 30px;
  max-height: 30px;
  background-image: url("../img/3d-scanner.svg");
  background-repeat: no-repeat;
  background-size: 30px 30px;
  position:relative;
  left:10px;
}
i.frIconBtn {
  display: inline-block;
  width: 15px;
  height: 15px;
  max-width: 15px;
  min-width: 15px;
  min-height: 15px;
  max-height: 15px;
  background-image: url("../img/3d-scanner.svg");
  background-repeat: no-repeat;
  background-size: 15px 15px;
}
.FarmersMapViewPane {
  background-color: #cdcdcd;
  height: 100%;
  padding: 0 !important;
}
.FarmersMapContainer{
  height: 100vh;
  width: 100%;
  border: solid 1px #666;
  margin : 0 auto;
  box-shadow: 2px 2px 8px #666;
}
.textSize30px {
  font-size: 30px;
}

.farmerSeperator {
  border: solid 1px #888;
}

.MapContainer {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.tabulator-cell {
  height: 100px !important;
  padding: 0 !important;
  vertical-align: middle;
}
.activeRow{
  height: 102px !important;
}
.tabulator-page {
  background-color: #a3c614 !important;
  color: #000;
}
.EmptySpacerContent {
  position: relative;
  display: inline-block;
  height: 85px !important;
  max-height: 86px !important;
  min-height: 84px !important;
}

.loaderWrap{
  position: relative;
  height: 100%;
}

.SectionSpinner{
  width: 80px;
  height: 80px;
  position: absolute;
  top: 25%;
  left: 40%;
}
.frActionLoader{
  position: absolute;
  top: 50%;
  right: -5%;
}

.farmerIcon{
  display: inline-block;
  width: 30px;
  max-width: 30px;
  min-width: 30px;
  height: 32px;
  max-height: 30px;
  min-height: 30px;
  background-image: url('../img/farmer.svg');
  background-size: 30px 30px;
  background-repeat: no-repeat;
  position:relative;
  right:12px;
}
.farmerIconFemale{
  display: inline-block;
  width: 30px;
  /* max-width: 30px;
  min-width: 30px; */
  height: 30px;
  /* max-height: 30px;
  min-height: 30px; */
  background-image: url('../img/farmer_female.png');
  background-size: 40px 39px;
  background-repeat: no-repeat;
  position:relative;
  right:15px;
}


.farmersUploadWrap{
  position: relative;
  width: 90%;
  margin: 0 auto;
}
.formDistLoadSpinnerWrap{
  display: inline-block;
  position: absolute;
  z-index: 1;
  right: 30px;
}
.formUpLoadSpinnerWrap{
  display: inline-block;
  position: absolute;
  left: 65%;
  z-index: 1;
}

.crops-join {
  white-space: break-spaces;
}
.load{
  /* display:none; */
  pointer-events:"";
  background-color: red;
}
.unload{
  display:block;
}
.disableDataClass{
  pointer-events: none;
  
  opacity:0.2;
   width: 100%;
  padding: 10px 5px 10px 10px;
  position: relative;
  /* background-color: red; */
}

.LoaderWrapper{
    width:200px;
    height:60px;
    position: absolute;
    left:50%;
    top:40%;
    transform: translate(-50%, -50%);
}
.circle{
    width:20px;
    height:20px;
    position: absolute;
    border-radius: 50%;
    background-color: #a3c614;
    left:15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

@keyframes circle{
    0%{
        top:60px;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40%{
        height:20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100%{
        top:0%;
    }
}
.circle:nth-child(2){
    left:45%;
    animation-delay: .2s;
}
.circle:nth-child(3){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.shadow{
    width:20px;
    height:4px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    top:62px;
    transform-origin: 50%;
    z-index: -1;
    left:15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow{
    0%{
        transform: scaleX(1.5);
    }
    40%{
        transform: scaleX(1);
        opacity: .7;
    }
    100%{
        transform: scaleX(.2);
        opacity: .4;
    }
}
.shadow:nth-child(4){
    left: 45%;
    animation-delay: .2s
}
.shadow:nth-child(5){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.LoaderWrapper span{
    position: absolute;
    top:75px;
    font-family: 'Lato';
    font-size: 20px;
    letter-spacing: 12px;
    color:blue;
    left:15%;
}

.exportdataclass {
  margin-top: 30px;
}
.hidingButton{
  visibility:hidden;
}
.mandatoryfields{
  border:1px solid red;
}
.hideEditClass{
  display:none;
  visibility: hidden;
}
.form-title{
  margin-left:90px;
  margin-top:-20px;
}
.form-check-input:disabled ~ .form-check-label {
  color:#6c757d;
  margin-left: 5px;
}
.form-check-input ~ .form-check-label {
  color:#6c757d;
  margin-left: 5px;
}
.farmer-photo{
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  height:98px;
  width:130px;
  /* box-shadow: 2px 6px 6px 2px #bea9a9 !important; */
}
