.analyticsMainCard{
    width: 99%;
    margin: 0 auto;
   /*  border-top: 5px solid #60b242; */
    border-top: 5px solid #A3C614;
    border-radius: 4px;
    padding: 0rem 1rem;
}
.analyticsFullWidthCards{
    width: 100%;
    margin: 0 auto;
   /*  border-top: 5px solid #60b242; */
    border-top: 5px solid #A3C614;
    border-radius: 4px;
    padding: 0rem 1rem;
}
.chartContainer{
    height: 250px;
}
.cropStageContainer{
    height: 400px;
}

.maskLayer{
    position: absolute;
    width: 100px;
    height: 50px;
    z-index: 10;
    display: inline-block;
    background-color: #fff;
}
.cropHealthMask{
    bottom: 5px;
    left: 10px;
}