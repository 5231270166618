.CropsListMainSection{
  position: relative;
  width: 90%;
  margin: 0 auto;
}

.CropsHeader {
  width: 100%;
  padding: 10px 5px 10px 10px;
  position: relative;
}
.CropsListHeading {
  font-family: "quicksand_bold";
  font-size: 1.3rem;
  display: inline-block;
  padding: 5px 0px !important;
  margin: 0 auto;
}

h4.CropsListHeading {
  font-size: 1.2rem;
}
h6.CropsListHeading {
  font-size: 1rem;
  font-family: "barlow_reg";
  font-weight: normal;
}
.CropsListSubHeading{
  display: inline-block;
  padding: 5px 0px !important;
  font-family: "barlow_reg";
  font-weight: normal;
  font-size: 1.1rem;
}

.verifiedStatusIcon{
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
}

.cropFarmersListTabWrap{
  width: 90%;
  margin: 0 auto;
}
.cropsFarmerTab{
  width: 100% !important;
}
.cropsForm{
    width: auto;
    height: auto;
}

.CroppingFarmerDetsIcon{
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    background-position: center;
    background-repeat: no-repeat;
}
.CroppingFarmerSowingIcon{
    background-image: url('../img/Sowing.svg');
}
.CroppingFarmerHarvestIcon{
  background-image: url('../img/harvest.svg');
}
.CroppingFarmerSeedVarietyIcon{
background-image: url('../img/seeds.svg');
}

i.viewAgroButton{
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url('../img/agronomy.png');    
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: 6px 3px;
  margin: 5px;
  cursor: pointer;
}

i.iconHolder{
display: inline-block;
width: 15px;
height: 15px;
background-size: 15px 15px;
background-repeat: no-repeat;
background-position: center;
}
i.areaIcon{
background-image: url('../img/area.svg');
}
.actionButtonCropFarmerDets{
width: 60px;
height: 60px;
display: inline-block;
border-radius: 50%;;
border: none;
box-shadow: 1px 1px 1px #777;
margin: 0 auto;
}
.actionButtonCropFarmerDets:hover{
transition: 0.4s;
box-shadow: 2px 2px 8px #444;
}

.mainCropsFarmerLoaderWrap{
width: 100%;
height: 250px;
position: relative;
}
.mainCropsFarmerLoader{
position: absolute;
width: 75px;
height: 75px;
top: 60%;
left: 45%;
}

.seasonDropdown {
margin-top: 10px;
}

.fixed-buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align buttons to the right */
  gap: 10px; /* Spacing between buttons */
  position: sticky;
  top: 0; /* Adjust as per requirement */
  background-color: white; /* Ensure background matches the rest of the page */
  z-index: 10; /* Ensure it stays above other elements */
  padding: 10px; /* Optional for spacing */
  margin-left: 500px;
}


.fpoCropsHeading {
width: 70% !important;
}

.headerComp {
text-align: center !important;
}

.td-checkbox {
text-align: center !important;
}
.procDetail td {
text-align: left;
}

.add-proc {
  color: white;
  margin-left: 82%;
  margin-bottom: 8px;
  padding: 7px;
  background-color: rgba(163, 198, 20, 1);
}

.nameError {
color: red;
}
.specError {
color: red;
}
.specError2 {
  color: red;
}
.dateError {
color: red;
}

.requiredfields{
color: red;
}
.requiredinputfields{
border:1px solid #ff0000
}
.update-dataColor{
color:#007bff;
margin-left:4px;
}
.requiredfields{
color: red;
}
.hidingButton{
  visibility:hidden;
}
.handleCropRowDisableclass{
  pointer-events: none; 
  opacity:0.2;
  visibility:hidden;
}
.slide_from_left {
--c: rgba(163, 198, 20, 1);
color: rgb(114, 49, 12);
font-size: 13px;
border: 0.3em solid var(--c);
border-radius: 0.5em;
/* width: 18em; */
height: 3em;
text-transform: uppercase;
font-weight: bold;
font-family: sans-serif;
letter-spacing: 0.1em;
text-align: center;
/* line-height: 3em; */
position: relative;
overflow: hidden;
z-index: 1;
transition: 0.5s;
background-color:#e9ecef ;
/* position: relative;
left: 10%; */
/* margin: 1em; */
}

.slide_from_left span {
position: absolute;
width: 25%;
height: 100%;
background-color: var(--c);
transform: translateY(150%);
border-radius: 50%;
left: calc((var(--n) - 1) * 25%);
transition: 0.5s;
transition-delay: calc((var(--n) - 1) * 0.1s);
z-index: -1;
}

.slide_from_left:hover {
color: white;
box-shadow: 2px 2px 8px #444;


}

.slide_from_left:hover span {
transform: translateY(0) scale(2);
}

.slide_from_left span:nth-child(1) {
--n: 1;
}

.slide_from_left span:nth-child(2) {
--n: 2;
}

.slide_from_left span:nth-child(3) {
--n: 3;
}

.slide_from_left span:nth-child(4) {
--n: 4;
}
.inputFieldOtp{
width:100%;
padding:5px;
}
.lds-ellipsis {
display: inline-block;
position: relative;
/* width: 80px;
height: 80px; */
left: 29%;
top: -83%;
}
.lds-ellipsis div {
position: absolute;
top: 33px;
width: 13px;
height: 13px;
border-radius: 50%;
background: rgb(163, 198, 20);
animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
left: 8px;
animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
left: 8px;
animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
left: 32px;
animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
left: 56px;
animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
0% {
  transform: scale(0);
}
100% {
  transform: scale(1);
}
}
@keyframes lds-ellipsis3 {
0% {
  transform: scale(1);
}
100% {
  transform: scale(0);
}
}
@keyframes lds-ellipsis2 {
0% {
  transform: translate(0, 0);
}
100% {
  transform: translate(24px, 0);
}
}
.disableOtpClass{
pointer-events: none;
opacity:0.5
}
.requiredUnitClass{
  padding:5px;
  border: 1px solid red;
  border-radius: 4px;

}
.UnitClass{
  padding:5px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #ced4da;
}
.requiredOfferedQuantityClass{
  padding:5px;
  width:"92%";
  border: 1px solid red;
  border-radius: 4px;

}
.startOfferedQuantityClass{
  padding:5px;
  width:"92%";
  border: 1px solid #ced4da;
  border-radius: 4px;

}
.CommodityTabIcon1 {
  background-image: url("../img/proc_brown.png");
}
.CommodityTabIcon2 {
  background-image: url("../img/commodity.png");
}