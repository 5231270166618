.message-form {
  /* background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin: 50px auto;
  border-radius: 6px;
  border:1px solid red; */


  background: #f6f3f3;
    margin: 20px auto;
    width: 30%;
    border: 2px dotted #ccc;
    margin-top: 50px;
    padding: 15px;
   
   cursor:pointer;

  font-size:14px;

 /* height:250px; */



}
.Farmers-message-inputbox{
    width:100%;
    padding:5px;
}
.farmer-message-button{
    /* float:right; */
    color: #fff;
    background-color: #3f9c35;
    border-color: #37892f;
}
.message-radio{
   
    cursor: pointer;
}
.dateTime input{
    padding:5px;
}

pre {
    text-align: left;
    white-space: pre-line;
  }

.requiredfields{
color: red;
}
.requiredinputfields{
border:1px solid #ff0000
}