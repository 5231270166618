.notification-box{
    margin-left: 100px;
   border: 2px solid rgb(132, 61, 20);
   text-shadow: 3px 3px 3px #c18888; 
   /* border-radius: 15px; */
   height:120px;
   width: 500px;
   position: relative;
   top:30px;
   background-color:rgb(162 , 198 , 23); 
   color:rgb(255, 255, 254);
   font-size:24px;
   font-weight:bolder;
   padding: 1px 5px 10px 130px;
   backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
   font-family: "barlow_reg";
}
.notification-box-mesage{
    font-size:18px;
   font-weight:500;
}
.update-image{
    position: absolute;
    top:10px;
    left: 360px;
    height: 100px;
    width:100px;
    border-radius: 15px;
}
.notifications{
    border-radius: 0px 0px 15px 15px;
    height:80px;
    width: 90%;
    position: relative;
    margin-top: 15px;
    top: 60px;
    left:100px;
    color: rgb(132, 61, 20);
    padding: 5px 5px 10px 100px;
    font-size: larger;
    font-weight:600;
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 8px 6px #a19090 !important;
    cursor:pointer;  
    font-family: "quicksand_med";
}
.reminder-message-box{
    /* padding: 10px;       */
    /* word-wrap: break-word;       */
    margin-top:5px;
    /* text-align: center;  */
    font-size: 20px;
    /* text-shadow: 3px 3px 3px #ababab; */
}
.reminder-title-box{
    border: 1px solid black;
    position: relative;
    height:52px;
    width:auto;
    margin-top:15px;
    margin-left: 8px;
    margin-right: 8px;
    background-color:rgb(132, 61, 20);
    padding:0 4px;
    border-radius: 5px; 
    color: aliceblue;  
    font-size: large;
    text-align: center;
    font-family: "quicksand_med";    
}
.reminder-hidden-secondbox{
    width:250px;
    position: relative;
    left:60px;
    margin-right:15px;
    margin-top:20px;
    height:265px;
    border-radius:5px;
    background-color:rgb(255, 255, 255);
    border: 1px solid black;
    cursor: pointer;
    display:inline-block;
    font-family: "quicksand_med";
    font-size:10px;
}
.dropdown-months{
    width:max-content;
    height: 40px;
    font-size:x-large;
    position: relative;
    left:100px;
    top:50px;
}
.notifiaction-message{
    color: rgb(84, 84, 207);
    position: relative;
    top:10px;
    font-size: large;
}
.date-time{
    float: right;
    color: black;
    font-size: medium;
}
.Accordian-key{
    position: relative;
    top: 5px;
}
.reminder-box{
    margin-left: 100px;
   /* border: 2px solid rgb(207, 56, 56); */
   border-radius: 15px;
   height:70px;
   width: 350px;
   position: relative;
   top:10px;
   background-color: rgb(132, 61, 20); 
   color:#f8e6e6;
   font-size:20px;
   font-weight:500;
   padding: 5px 5px 10px 10px;
   backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  font-family: "quicksand_med";
}
.update-reminder-image{
    position: absolute;
    top:10px;
    left: 230px;
    height: 50px;
    width:25%;
    border-radius: 15px;
}
.No-data{
    height:50px;
    position: relative;
    top:70px;
    left:-3px;
    font-size: larger; 
    width: 95%;
    background-color: #ffff;
    border-radius: 3px;
    box-shadow: 2px 2px 8px rgb(182, 179, 179);
    padding: 10px;
}
.reminder-date{
    width:90%;
    margin-left: 100px;
    margin-top: 10px;
}
.notification{
    border-radius: 0px 0px 15px 15px;
    height:80px;
    width: 110%;
    position: relative;
    margin-top: 15px;
    /* top: 60px; */
    left:50px;
    color: rgb(132, 61, 20);
    padding: 5px 5px 10px 100px;
    font-size: larger;
    font-weight:600;
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 8px 6px #a19090 !important;
    cursor:pointer;  
    font-family: "quicksand_med";
}
.cardbody{
    height:140px;
    /* width:150px; */
}

.dashboard:hover > div.cardbody  > img {
    animation-name: notificationIconAnimate;
    animation-duration: 1.5s;
  }
  .dashboard:hover > div.cardbody  {
    animation-name: notificationIconAnimate;
    animation-duration: 1.5s;
  }
  @keyframes notificationIconAnimate {
    from {
      width: 310px;
      height: 140px;
    }
    to {
      width: 310px;
      height: 180px;
    }
  }
  
  @-moz-keyframes notificationIconAnimate {
    from {
      background-size: 200px 200px;
    }
    to {
      /* background-size: 200px 200px; */
    }
  }
  
  @-webkit-keyframes notificationIconAnimate {
    from {
      /* background-size: 170px 170px; */
    }
    to {
      background-size: 200px 200px;
    }
  }
  @-o-keyframes notificationIconAnimate {
    from {
      /* background-size: 170px 170px; */
    }
    to {
      background-size: 200px 200px;
    }
  }
  .dashBoardIcon {
    height: 140px;
    width: 310px;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0px auto !important;
    text-align: center;
    border: 0px solid #ccc;
  }
  .dashboard {
    border: solid 1px rgba(163, 198, 20, 1);
    cursor: pointer;
    box-shadow: 0px 1px 5px 2px #777;
  }
  .dashboard:hover {
    box-shadow: 2px 2px 50px 0px #777;
  }
  .card-inner {
    height: auto;
    margin-bottom: 19px;
  }
  