.header{
    width:100%;
    height:100%;
    background-color:#a3c614;
    
    padding: 10px;
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
  }

  .dvaraWhite{
    color: white;
  }

  .pointer{
    cursor:pointer;
  }

  .marginBottom{
   
  
  
  margin-bottom:1rem ;
  
  }

  .SoilTestingHeading{
    font-family: Arial, Helvetica, sans-serif ;
  font-weight: 600 !important;
  margin-left:0 !important;
  }

  .SoilTestingMainSection{
    position: relative;
  width: 96%;
  margin-left: auto;
  padding-top: 1rem;
  }
  .Center{
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .SoilTestingUploadSection{
   
    
  
    margin-top: 1rem;
    background-color: rgba(226, 220, 220, 0.797);
   padding-top: 2rem;
   padding-right: 2rem;
   padding-left: 2rem;
   padding-bottom: 2rem;
   

  }

  .inputFields{
    
       /* padding:0.9rem !important; */
       
      height:2rem;
      vertical-align: middle;
     padding-left: 0.9rem;
     padding-right: 0.9rem;
        border: 2px solid gray;
     width:100%;
      
      border-radius: 0.5rem;
     
  }

  .inputFieldsDropdown{
    
  
     border: 2px solid gray;
  width:100%;
  height:2rem;
   border-radius: 0.5rem;
  
}

.inputFieldsDropdown:hover,.inputFieldsDropdown:active{
    
  border-color: black;
 

}

  .inputFields:hover{
    border-color: black;

  }
  
  
  
  

  .fileUpload{
    display:none
  }

  .justifyContentSpaceBetween{
    width:100%;
   display: flex;
   justify-content:space-between;
    
  }
  .fertilizerSection{
    width:75% !important;
    margin-left: auto;
    margin-right: auto;
  }
  .justifyContentCenter{
    width:100%;
      display: flex;
      justify-content:center;
       
    
  }

  .flexColumn{
    display:flex;
    flex-direction:column;
  }

  .card_shadow{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    
  }
  .justify_Content{
    justify-content: center;
  }
  .justifyContentSubmit{
     width:100%;
      display: flex;
      justify-content:right;
       
    
  }
  .triggerReportHistory{
    background-color: #a3c614;
    border:none;
    padding:0.5rem 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    font-weight: bold;
   
  }

  .vertical_align{
    margin-top: auto;
    margin-bottom: auto;
    padding-left:10px;
  }
  
  .download_view_delete{
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    
  
  }

  .fileNameSoilTesting{
    max-width: 250px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hover{
    cursor: pointer;
  }

  .inputFieldContainer{
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding-left:1rem;
    padding-right: 1rem;
    border: 2px solid gray;
    /* padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important; */
    width:100%;
    height:2rem;
    vertical-align: middle;
  
     border-radius: 0.5rem;
  }
  .inputFieldContainer:hover{
    border: 2px solid black;
  }
  

  .verticalAlign{
    margin-top: auto;
    margin-bottom: auto;
  }


  .view_div{
    width: 100%;
    height: 100%;
    position: fixed;
    left:0;
    top: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 9999999
    
  }

  .close-div{
    width:100%;
    height: '8%';
    background-color: rgba(0,0,0, 0.6);
    display: flex;
    flex-direction: row-reverse;
    padding: 1%;
    
}

.bold{
  font-weight: bold;
}





input::placeholder {
 font-size: small;
}

.submitSoilReport{
  background-color: #a3c614 !important;
  border:none !important;

}

.submitSoilReport:hover{
  background-color: #97bc05 !important;
  border:none !important;

}
.submitSoilReport:active,.submitSoilReport:focus{
  background-color: #97bc05 !important;
  transform: scale(0.9);
  border:none !important;

}

.paddingLeft{
  padding-left: 1rem;
}

.paddingRight{
  padding-right: 1rem;
}

.paddingTop{
  padding-top: 1rem;
}
.paddingBottom{
  padding-bottom: 1rem;
}

.embeddedFile{
  height:92%;
  width:100%;
  display:flex;
  justify-content: center;
}

.smallFont{
  font-size: small;
}

.mandatory{
  border:2px solid red !important;
}
  