
/**Left Bar section starts **/

.leftMenuBarSection {
    position: fixed;
    left: 0;
    top: 56px;
    width: 50px;    
    height: 100%;
    border-top: solid 2px rgba(163, 198, 20, 1);
    max-width: 50px;
    z-index: 10020;
    box-shadow: 3px 3px 8px #777;
    -moz-box-shadow: 3px 3px 8px #777;
    -webkit-box-shadow: 3px 3px 8px #777;
    -o-box-shadow: 3px 3px 8px #777;
}

.sideBarNav{
    width: 100%;
    position: relative;
    display: block;
    padding: 30px 0px;
}

.sideBarNav > ul{
    width: 100%;
    position: relative;
    display: block;
    padding: 0;
    margin: 0 auto;
}

.sideBarNav > ul > a > li{
    display: block;
    width: 45px;
    max-width: 45px;
    height: 45px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: center;
    padding: 20px 0px 0px 50px;
}
.sideBarNav > ul > a > li:hover{
    background-color: rgba(163, 198, 20, 1);
}

.sideBarNav > ul > a > li.FarmersMenu{
    background-image: url('../img/farmers.png')  ;

}
.sideBarNav > ul > a > li.FarmersMenu:hover{
    background-image: url('../img/farmers_red.png')  ;

}
.sideBarNav > ul > a > li.CropsMenu{
    background-image: url('../img/cropping.png')  ;
}
.sideBarNav > ul > a > li.CropsMenu:hover{
    background-image: url('../img/cropping_red.png')  ;
}
.sideBarNav > ul > a > li.LandholdingMenu{
    background-image: url('../img/landholding.png')  ;
}
.sideBarNav > ul > a > li.LandholdingMenu:hover{
    background-image: url('../img/landholding_red.png')  ;
}
.sideBarNav > ul > a > li.InputProductMenu{
    background-image: url('../img/input_green.png')  ;
}
.sideBarNav > ul > a > li.InputProductMenu:hover{
    background-image: url('../img/input_brown.png')  ;
}
.sideBarNav > ul > a > li.CommodityMenu{
    background-image: url('../img/proc_green.png')  ;
}
.sideBarNav > ul > a > li.CommodityMenu:hover{
    background-image: url('../img/proc_brown.png')  ;
}
.sideBarNav > ul > a > li.WspMenu{
    background-image: url('../img/wsp_green.png')  ;
}
.sideBarNav > ul > a > li.WspMenu:hover{
    background-image: url('../img/wsp_brown.png')  ;
}

.sideBarNav > ul > a > li.MilkMenu{
    background-image: url('../img/milk_green.png') ;
}
.sideBarNav > ul > a > li.MilkMenu:hover{
    background-image: url('../img/milk_brown.png') ;

}
.sideBarNav > ul > a > li.ActMenu{
    background-image: url('../img/act_green.png')  ;
}
.sideBarNav > ul > a > li.ActMenu:hover{
    background-image: url('../img/act_brown.png')  ;
}
.sideBarNav > ul > a > li.BoMenu{
    background-image: url('../img/bo_green.png')  ;
}
.sideBarNav > ul > a > li.BoMenu:hover{
    background-image: url('../img/bo_brown.png')  ;
}
.sideBarNav > ul > a > li.FinMenu{
    background-image: url('../img/act_green.png')  ;
}
.sideBarNav > ul > a > li.FinMenu:hover{
    background-image: url('../img/act_brown.png')  ;
}
.sideBarNav > ul > a > li.AgronomicsMenu{
    background-image: url('../img/agronomic.png')  ;
}
.sideBarNav > ul >  a > li.AgronomicsMenu:hover{
    background-image: url('../img/agronomic_red.png')  ;
}
.sideBarNav > ul > a > li:hover .menuExtension{
    display: block;
}
.sideBarNav > ul > a > li.FarmerMenu{
    background-image: url('../img/farmerOrder_Green.png')  ;
}
.sideBarNav > ul > a > li.FarmerMenu:hover{
    background-image: url('../img/farmerOrder_Brown.png')  ;
}
.menuExtension{
    display: none;
    position: absolute;
    width: 150px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    vertical-align: middle;
    z-index: 10;
    font-weight: bold;
}
.dashboardMenuLabel{
    top:0;
    left: 50px;
}
.farmerMenuLabel{
    top:0;
    left: 50px;
    
}
.cropMenuLabel{
    top: 90px;
    left: 50px;
}
.landHoldingMenuLabel{
    top: 45px;
    left: 50px;
}
.InputMenuLabel{
    top: 135px;
    left: 50px;
}

.CommodityMenuLabel{
    top: 180px;
    left: 50px;
}
 
.MilkMenuLabel{
    top: 360px;
    left: 50px;

}

.WspMenuLabel{
    top: 225px;
    left: 50px;
}

.ActMenuLabel{
    top: 270px;
    left: 50px;
}

.FinMenuLabel{
    top: 385px;
    left: 50px;
}


.BoMenuLabel{
    top: 315px;
    left: 50px;
}
.agroMenuLabel{
    top: 165px;
    left: 50px;
}
.todayForecast {
    margin-top: 17px !important;
    margin-left: 28px;
}
.buttonMenu{
    display: block;
    cursor: pointer;
}
.buttonMenu:hover{
    animation-name: menuIconAnimate;
    animation-duration: 1.5s;
    -moz-animation-name: menuIconAnimate;
    -moz-animation-duration: 1.5s;
    -o-animation-name: menuIconAnimate;
    -o-animation-duration: 1.5s;
    -webkit-animation-name: menuIconAnimate;
    -webkit-animation-duration: 1.5s;
}
/** Left bar section ends **/

/** Kyeframes function definitions starts **/

@keyframes menuIconAnimate{
    from { background-size: 18px 18px; }
    to { background-size: 25px 25px; }
}

@-moz-keyframes menuIconAnimate{
    from { background-size: 18px 18px; }
    to { background-size: 25px 25px; }
}

@-webkit-keyframes menuIconAnimate{
    from { background-size: 18px 18px; }
    to { background-size: 25px 25px; }
}
@-o-keyframes menuIconAnimate{
    from { background-size: 18px 18px; }
    to { background-size: 25px 25px; }
}
/** Kyeframes function definitions ends **/
