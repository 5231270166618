.landholdingHeader {
  width: 95%;
  margin: 30px auto;
}

@media (min-width: 360px) {
  .landholdingHeader {
    width: 95%;
    margin: 0px auto;
  }
  .LandHoldingMainCardsWrap {
    position: relative;
    z-index: 20;
    background-color: #ffff;
    border-radius: 3px;
    box-shadow: 2px 2px 8px #888;
    padding: 5px;
  }
}
@media (min-width: 576px) {
  .landholdingHeader {
    width: 95%;
    margin: 30px auto;
  }
  .LandHoldingMainCardsWrap {
    position: relative;
    z-index: 20;
    background-color: #ffff;
    border-radius: 3px;
    box-shadow: 2px 2px 8px #888;
    padding: 10px;
  }
}
@media (min-width: 768px) {
  .landholdingHeader {
    width: 95%;
    margin: 30px auto;
  }
}

.landHoldingMainCardsIcon {
  display: inline-block;
  position: absolute;
  /* left: 30px; */
  /* left: 26px; */
  left: 22px;

  top: 10px;
  width: 55px;
  height: 55px;
  background-size: 45px 45px;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.sitesInfoIcon {
  background-image: url("../img/information.svg");
}
.CompanyIcon {
  background-image: url("../img/company.svg");
}
.IrrigIcon {
  background-image: url("../img/irrigation.svg");
}
.landHoldingPageLoaderWrap {
  position: absolute;
  top: 10%;
  width: 100%;
  height: 500px;
}
.landHoldingPageLoader {
  position: absolute;
  top: 45%;
  left: 45%;
  width: 80px;
  height: 80px;
}
.landHoldingPageTabLoader {
  position: absolute;
  top: 45%;
  left: 45%;
  width: 80px;
  height: 80px;
}
.landHoldingPageContenteHeader{
  position: fixed;
  width: 98%;
  top: 57px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 1);
}

.LandHoldingMainCardsWrap {
  position: relative;
  /* top: 65px; */
  /* left: 35px; */
  left:2%;
  width: 97%;
 /*  z-index: 1000; */
  background-color: #ffff;
  border-radius: 3px;
  box-shadow: 2px 2px 8px #888;
  padding: 10px;
}
.RainFedIcon {
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 10px;
  width: 55px;
  height: 55px;
  background-image: url("../img/rain_dvaraBrownColor.svg"),
    url("../img/forest.svg");
  background-size: 30px 30px, 50px 50px;
  background-position: right top, left bottom;
  background-repeat: no-repeat, no-repeat;
  opacity: 0.8;
}
.FarmerOwnedIcon {
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 10px;
  width: 55px;
  height: 55px;
  background-image: url("../img/farmer_black_sill.svg"),
    url("../img/barn_farm.svg");
  background-size: 50px 50px, 30px 30px;
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  opacity: 0.8;
}

.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 5px 10px;
  background-color: #e5e5e5;
  color: #333;
  height: 70px;
  border-radius: 5px;
  cursor: pointer;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #ababab;
  color: #333;
  transition: 0.3s linear all;
  background-color: #a3c614;
}
.card-counter.active {
  box-shadow: 4px 4px 20px #ababab;
  color: #333;
  transition: 0.3s linear all;
  background-color: #a3c614;
}

.MuiTableCell-root {
  padding: 10px !important;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ef5350;
  color: #fff;
}

.card-counter.success {
  background-color: rgba(163, 198, 20, 1);
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 5em;
  opacity: 0.2;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 10px;
  /* font-size: 22px; */
   /* font-size: 18px; */
   font-size: 14px;

  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  bottom: 5px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.8;
  display: block;
  font-size: 14px;
  text-align: right;
}

.landHoldingMainTab {
  margin: 10px 0;
  height: auto;
}

.landHoldingSiteListWrap {
  width: 100%;
  margin: 0 auto;
}

.sitesDetailsWrap {
  background-color: #dedede;
  padding: 10px;
}
i.siteDetailIcons {
  display: inline-block;
  width: 30px;
  height: 30px;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  padding: 10px 0px 0px 0px;
  background-position: 0px 6px;
}
.SiteAreaIcon {
  background-image: url("../img/iorderarea.png");
}
.SowingIcon {
  background-image: url("../img/Sowing.svg");
}
.HarvestIcon {
  background-image: url("../img/harvest.svg");
}
.CropIcon {
  background-image: url("../img/wheat.svg");
}
.CropVarietyIcon {
  background-image: url("../img/seeds.svg");
}
.LandWaterTypeIcon {
  background-image: url("../img/hydroponic-gardening.svg");
}
.DurationIcon {
  background-image: url("../img/calendar.svg");
}

span.minimizeCard {
  border: solid 1px #999;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  top: 30%;
  padding: 2px 0px 0px 4px;
  width: 20px;
  height: 20px;
  font-size: 10px;
}
span.minimizeCard:hover {
  box-shadow: 2px 2px 5px #555;
  cursor: pointer;
}

span.siteDetailsTitle {
  position: relative;
  display: inline-block;
  /* padding: 10px 0px; */
   padding: 5px 0px;
}

.sitesMapCardWrap {
  padding: 0px 15px 0px 0px;
}
.sitesMapHolderCard {
  width: 100%;
  height: 600px;
  max-height: 600px;
  min-height: 600px;
}
.MuiTableCell-paddingNone {
  width: 13% !important;
}

/* next try */
/* .MuiPaper-elevation2 {
  margin-left: 30px;
} */
/* .farmerListHeading {
  margin-left: 30px;
} */
.headingMargin {
  margin-left: 30px;
}
.MapViewPane{ 
  /* background-color: #cdcdcd; */
   background-color: white;
  position: relative;
  padding: 0 !important;
  margin-top:15px;
}
.MapContainerHolder{
  position: relative;
  width: 100%;
  height: 100vh;
}
.MapContainer{
  position:fixed;
  width: 40%;
  height: 100%;
  border: solid 1px #666;
  margin : 0 auto;
  box-shadow: 2px 2px 8px #666;
}
 
.carousel-indicators li{
      background-color:rgba(114, 49, 12, 1);
} 
.carousel-indicators{
  /* bottom: -30px; */
   /* bottom: -15px; */
   bottom:-19px;
}

.cardBodyNoPadding{
  padding:0px;
}
.card-header-editing{
  padding: 5px;
  /* background-color: #e5e5e5; */
}
.carousel-editing{
  padding-bottom: 10px;
}
.title-editing{
  font-size: 18px;
}
.leaflet-popup-content{
  width:270px;
 
}


.actionMessageToastHolder{
  position: fixed;
  bottom: 10px;
  left: 50px;
}
.noPadding2{
  position:relative;
  left:4px;
}
.colorCateg{
  color:rgba(114, 49, 12, 1);
}
.villageHeading{
  font-size: 25px;
    margin-top: 28px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}
.village-box-details {
  padding: 20px;
  border-radius:20px;
  /* line-height:2rem; */

  margin-top: 10px;
  font-size: 20px;
  box-shadow: inset 10px 10px 50px #a3c614;
}
.selfieWrap{
  display:inline-block;
  width:100%;
  padding-bottom:1rem;
  border-bottom:1px solid #a3c614;
  background-color: aliceblue;
  padding:20px;
  /* border-top:1px solid #a3c614; */
  
  box-shadow: 2px 2px 2px lightgoldenrodyellow;


  /* border-bottom:1px solid #ced4da; */
  border-radius:10px;
}
.selfieDivFirst{
  width:25%;
  display:inline-block;
  margin-left:12px;
  overflow:hidden;
  border-radius: 10px;
  max-height: 150px;

  
}
.selfieDivFirst img{
  /* width:100%;
  height:150px; */
  max-width: 100%;
  /* max-height: 100%; */
  object-fit: contain;
  overflow:hidden;
  transition: all .2s ease-in-out; 
  border-radius: 10px;
  cursor:pointer;
}

.selfieDivFirst img:hover{
  overflow:hidden;
  /* transform:scale(1.5); */
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Safari 3-8 */
  transform: scale(1.3); 
  border-radius: 10px;
}

.selfieDivSecond{
  width:68%;
  display:inline-block;
  vertical-align: top;
}
.siteDetailsWrap{
  background-color: aliceblue;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

}
.inputDisabled{
border:1px solid rosybrown;
cursor:not-allowed;
background-color:#fbfbfb
}

i._siteDetailIcons_ {
  display: inline-block;
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  background-repeat: no-repeat;
  background-size: 34px 34px;
  background-position: 6px 6px;
  
  
}

.siteIcon{
  background-image: url('../img/SiteIcon.png');
}

.soilTestingIcon{
  background-image: url('../img/soilTesting.png');
}

.soilTestinglink{
  color: rgb(33, 33, 33);
}


